import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Popconfirm, Row, Select, Tabs, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { filterOption, limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, maps_key, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import AutoComplete from "react-google-autocomplete";
import S3FileUpload from 'react-s3';
import CargandoTabla from "./cargando"
import { useParams } from "react-router-dom"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import SelectorGeneral from "../General/selector"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai"
import { GrAdd, GrDocumentText } from "react-icons/gr"
import { codigos_area, codigos_pais } from "../../lib/internacional"
import { GoDeviceMobile } from "react-icons/go"
import { MdOutlineDynamicFeed } from "react-icons/md"
import Contactos from "../Contactos"
import { BiUser } from "react-icons/bi"
import SelectorClientes from "../Clientes/selector"

const DetallesContacto = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "cliente" })

    const requeridos = [
        { value: 'nombres', label: 'Nombres' }
      ];
    const module = 'contactos'

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const eliminarRecurso = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return window.location = rutas.contacts.slug
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChangeSelectSimple = (e,name) => {
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }
    const handleChangeVehiculoCustom = (e,name) => {
        console.log({e,name})
        usuario[name] = e._id
        if(name === "id_tipo") usuario.tipo = e.tipo
        if(name === "marca") usuario.modelo = ""
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const seleccionarConductor = (data) => {
            usuario.id_usuario = data._id
            console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeSelect = (name, value) => {
        usuario[name] = value
        return setUsuario({...{}, ...usuario})
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "movil"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        usuario.autorizado = "si"

        if(usuario.emails_adicionales) if(usuario.emails_adicionales.length > 0){
            const invalidos = usuario.emails_adicionales.filter(e => !e.valid || !e.email)
            if(invalidos.length > 0) return messageApi.error(`Emails inválidos: ${invalidos.map(ma => ma.email ? ma.email : "Email vacío").join(', ')}`)  
        }
        
        if(usuario.moviles_adicionales) if(usuario.moviles_adicionales.length > 0){
            const invalidos = usuario.moviles_adicionales.filter(e => !e.pais_codigo ||!e.movil)
            if(invalidos.length > 0) return messageApi.error(`Números de teléfono inválidos: ${invalidos.map(ma => {
                const codigo = ma.pais_codigo ? ma.pais_codigo : "Código area vacío"
                const numero = ma.movil ? ma.movil : "Número vacío"
                return `${codigo} ${numero}`
            }).join(', ')}`)  
        }

        setLoading(true)
        const url = `${urlapi}/${module}`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.contacts.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const handleChangeCat = (e) => {
        usuario.id_cargo = e._id
        setUsuario(usuario)
        setCondicionSubCategoria(prev => ({...prev, id_cargo: e._id }))
    }

    const handleChangeSubCat = (e) => {
        usuario.id_sub_categoria = e._id
        setUsuario({...{}, ...usuario})
    }

    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        usuario.moviles_adicionales[pos][name] = value
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerMovil = (i) => {
        usuario.moviles_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }
    
    const anadirMovilAdicional = () => {
        if(!usuario.moviles_adicionales) usuario.moviles_adicionales = []
        usuario.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const anadirEmailAdicional = () => {
        if(!usuario.emails_adicionales) usuario.emails_adicionales = []
        usuario.emails_adicionales.unshift({
            email: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerEmail = (i) => {
        usuario.emails_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        usuario.emails_adicionales[pos][name] = value
        usuario.emails_adicionales[pos].valid = validateEmail(value)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!usuario.emails_adicionales) return false
        return <div>
            {
                usuario.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

        if(!usuario.moviles_adicionales) return false
        return <div>
            {
                usuario.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label="Código de área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const handleChangeCustom = (value,name) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.contacts.slug,
                    title: <span>Contactos</span>,
                },
                {
                    title: 'Detalles de contacto',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de contacto</Title>
            <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                  >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                        <Card size="small">
            <Form layout="vertical">
            <Row gutter={10}>
            <Col md={24}>

                        <SelectorClientes  value={usuario.id_cliente} titulo="Empresa asociada" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />

                        <Form.Item label="Estado" className="mb-2">
                        <Select options={[{ value: 'active', label: "Activo" },{ value: "inactive", label: "Inactivo" }]} showSearch filterOption={filterOption} defaultValue={!usuario.status ? "" : usuario.status} name="status" onChange={(e) => handleChangeSelect("status", e)} />
                        </Form.Item>
                    </Col>
            <Col md={12}>
                        <Form.Item label="Nombres" required className="mb-2">
                        <Input name="nombres" value={usuario.nombres} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Apellidos" className="mb-2">
                        <Input name="apellidos" value={usuario.apellidos} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="mb-3">
                        <Form.Item label="Cargo" className="mb-2">
                        <Input name="cargo" value={usuario.cargo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Área" className="mb-2">
                        <Input name="area" value={usuario.area} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Dirección" className="mb-2">
                        <Input name="direccion" value={usuario.direccion} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Ciudad" className="mb-2">
                        <Input name="ciudad" value={usuario.ciudad} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="País" className="mb-2">
                        {/* <Input name="pais" value={campo.pais} onChange={handleChange} /> */}
                        <Select options={codigos_pais} showSearch filterOption={filterOption} defaultValue={usuario.pais} name="pais" onChange={(e) => handleChangeSelect("pais", e)} />
                        </Form.Item>
                    </Col>
                    <Col md={24} className="mb-3">
                        <Form.Item label="Nota" className="mb-2">
                        <Input name="notas" value={usuario.notas} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <SelectorGeneral module="categorias" value={usuario.id_cargo} titulo="Categoría del cargo" condicion_default={{ tipo: "cargo" }} onChange={handleChangeCat} />
                    </Col>
                    <Col md={24} className="mb-3">
                        <Title level={5} className="mt-0 mb-2"><AiOutlineMail /> Email</Title>
                        <Input name="email" placeholder="Escribe tu email" value={usuario.email} onChange={handleChange} />
                    </Col>
                    <Col md={24}>
                        <Title level={5} className="mt-0 mb-2"><GoDeviceMobile /> Móvil</Title>
                    </Col>
                    <Col md={12}>
                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={usuario.pais_codigo} onChange={(e) => handleChangeCustom(e,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                    </Col>
                    <Col md={12}>
                        <Input name="movil" value={usuario.movil} onChange={handleChange} />
                    </Col>
                
                <Col md={24} xs={24}>
                    <Divider />
                    <Title className="mt-0" level={4}>Datos de contacto</Title>
                    <Row gutter={15}>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        </Col>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Col>
                    </Row>
                </Col>
                
                
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
            </Card>

                </Col>
                <Col md={12}>
                    <Card size="small">
                    <Tabs defaultActiveKey="1" items={[
                        {
                            key: "1",
                            label: <div size="small" style={{ textAlign: "center" }}><GrDocumentText style={{ fontSize: 20 }} /> <Title level={4} className="m-0">MÁS INFORMACIÓN</Title></div>,
                            children: <CamposPersonalizadosRecurso id_target={id} tipo="contacto" />
                        },
                        {
                            key: "2",
                            label: <div size="small" style={{ textAlign: "center" }}><MdOutlineDynamicFeed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">ACTIVIDAD</Title></div>,
                            children: <div></div>
                        }
                    ]} />
                    </Card>
                </Col>
            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesContacto