export const modulos_notificaciones = {
    opciones: [
        {
            slug: "portal-clientes",
            label: "Portal clientes",
            opciones: [
                {
                    slug: "nuevo",
                    label: "Recibir una solicitud de carga",
                    descripcion: "Cuando soliciten alguno de tus vehículos publicados en el portal"
                },
                {
                    slug: "publicar",
                    label: "Cuando se venza una disponibilidad de vehículos publicada en el portal cliente",
                    descripcion: "Las disponibilidades siempre tienen fechas específicas o fechas de vencimiento, elige quien debe ser notificado cuando alguna de tus disponibilidades venza."
                },
            ]
        },
        {
            slug: "flota",
            label: "Flota",
            opciones: [
                {
                    slug: "conexion-desconexion",
                    label: "Cuando un vehículo conecte el GPS o lo desconecte de la plataforma",
                    descripcion: "Tanto cuando se conecten al GPS como cuando se desconecten, recibe una notificación"
                },
                {
                    slug: "vencimiento-vehiculo",
                    label: "Cuando se venza un requisito de un vehículo",
                    descripcion: "Recibe una copia de la notificación cuando se venza un requisito de un vehículo"
                },
                {
                    slug: "vencimiento-conductor",
                    label: "Cuando se venza un requisito de un conductor",
                    descripcion: "Recibe una copia de la notificación cuando se venza un requisito de un conductor"
                },
                {
                    slug: "geocerca-informativa",
                    label: "Cuando un vehículo entre o salga de una geocerca informativa",
                    descripcion: "Recibe una notificación cuando un vehículo entre o salga de una geocerca informativa"
                },
                {
                    slug: "geocerca-restriccion",
                    label: "Cuando un vehículo entre o salga de una geocerca de restricción",
                    descripcion: "Recibe una notificación cuando un vehículo entre o salga de una geocerca de restricción"
                }
            ]
        },
        {
            slug: "tickets",
            label: "Tickets",
            opciones: [
                {
                    slug: "nuevo",
                    label: "Creación de nuevo ticket",
                    descripcion: "Se ha creado un nuevo ticket"
                },
                {
                    slug: "actualizado",
                    label: "Actualización de ticket",
                    descripcion: "Se ha actualizado un ticket"
                },
                {
                    slug: "asignado",
                    label: "Asignación de ticket",
                    descripcion: "Se ha asignado un ticket"
                },
                {
                    slug: "comentario",
                    label: "Nuevo comentario",
                    descripcion: "Se ha agregado un nuevo comentario"
                },
                {
                    slug: "cerrado",
                    label: "Cierre de ticket",
                    descripcion: "Se ha cerrado un ticket"
                }
            ]
        },
        {
            slug: "rutas",
            label: "Rutas",
            opciones: [
                {
                    slug: "nueva",
                    label: "Creación de nueva ruta",
                    descripcion: "Se ha creado una nueva ruta"
                },
                {
                    slug: "actualizada",
                    label: "Actualización de ruta",
                    descripcion: "Se ha actualizado una ruta"
                },
                {
                    slug: "asignada",
                    label: "Asignación de ruta",
                    descripcion: "Se ha asignado una ruta"
                },
                {
                    slug: "completada",
                    label: "Ruta completada",
                    descripcion: "Se ha completado una ruta"
                }
            ]
        },
        {
            slug: "expediciones",
            label: "Expediciones",
            opciones: [
                {
                    slug: "completada",
                    label: "Expedición completada",
                    descripcion: "Se ha completado una expedición"
                }
            ]
        }
    ]
}