import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { codigos_area, codigos_pais } from "../../lib/internacional";
import { filterOption } from "../../lib/helpers/helpers";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import SelectorClientes from "../Clientes/selector";

const CrearNuevoContacto = (props) => {
    const {
        default_payload
    } = props
    const [ campo, setCampo ] = useState(default_payload ? default_payload : {})
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = 'contactos'

    const requeridos = [
        { value: 'nombres', label: 'Nombres' }
    ];

    useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])
    
    const crearCampo = async () => {
        
        let faltantes = []
        requeridos.map(field => {
            if(!campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        
        if(campo.pais_codigo || campo.movil) if(!campo.pais_codigo || !campo.movil) return messageApi.error(`Completa el número de teléfono principal`)  

        setLoading(true)
        return fetch(`${urlapi}/${module}`,{
            method:'POST',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        campo[name] = value
        return setCampo(campo)
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo(campo)
    }


    const handleChangeSelect = (name, value) => {
        console.log(name, value)
        campo[name] = value
        return setCampo(campo)
    }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes key={key_input_filter} titulo="Empresa asociada" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={() => {
            setKeyInputFilter(key_input_filter + 1)
            return showModal()
        }}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nuevo contacto" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            {mostrarSelectorClientes()}
            <Form layout="vertical">
                <Row gutter={15} >
                    <Col md={24}>
                        <Form.Item label="Estado" className="mb-2">
                        {/* <Input name="pais" value={campo.pais} onChange={handleChange} /> */}
                        <Select options={[{ value: 'active', label: "Activo" },{ value: "inactive", label: "Inactivo" }]} showSearch filterOption={filterOption} defaultValue={!campo.status ? "" : campo.status} name="status" onChange={(e) => handleChangeSelect("status", e)} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Nombres" required className="mb-2">
                        <Input name="nombres" value={campo.nombres} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Apellidos" className="mb-2">
                        <Input name="apellidos" value={campo.apellidos} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Cargo" className="mb-2">
                        <Input name="cargo" value={campo.cargo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Área" className="mb-2">
                        <Input name="area" value={campo.area} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Dirección" className="mb-2">
                        <Input name="direccion" value={campo.direccion} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Ciudad" className="mb-2">
                        <Input name="ciudad" value={campo.ciudad} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="País" className="mb-2">
                        {/* <Input name="pais" value={campo.pais} onChange={handleChange} /> */}
                        <Select options={codigos_pais} showSearch filterOption={filterOption} defaultValue={campo.pais} name="pais" onChange={(e) => handleChangeSelect("pais", e)} />
                        </Form.Item>
                    </Col>
                    
                    <Col md={24} className="mb-3">
                        <Form.Item label="Nota" className="mb-2">
                        <Input name="notas" value={campo.notas} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={24} className="mb-3">
                        <Title level={5} className="mt-0 mb-2"><AiOutlineMail /> Email</Title>
                        <Input name="email" placeholder="Escribe tu email" value={campo.email} onChange={handleChange} />
                    </Col>
                    <Col md={24}>
                        <Title level={5} className="mt-0 mb-2"><GoDeviceMobile /> Móvil</Title>
                    </Col>
                    <Col md={12}>
                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={campo.pais_codigo} onChange={(e) => handleChangeCustom(e,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                    </Col>
                    <Col md={12}>
                        <Input name="movil" value={campo.movil} onChange={handleChange} />
                    </Col>
                </Row>
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoContacto