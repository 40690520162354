import { useDispatch, useSelector } from "react-redux"
import data, { limit_page_options, urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Checkbox, Col, Divider, Form, Input, Pagination, Row, Select, Switch, Table, Tabs, Tag, Typography, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaUTCATexto, fechaUTCATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons";
import { formatTime, obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import ContadoresVehiculos from "./contadores"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { rutas } from "../../lib/routes/routes"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles"
import MostrarEmails from "../../lib/helpers/mostrar_emails"
import { stringByStatusTrip } from "../../lib/helpers/viajes"
import HelperModal from "../../subComponents/general/helperModal"
import SelectorGlobal from "../../subComponents/general/selector"
import { getLabelForVehicle } from "../../lib/helpers/selectores"
import { formatoMoneda, obtenerLimitesPagina, obtenerQueryTiempoEntrega, obtenerTiemposEntregaOpciones } from "../../lib/helpers/main"
import SelectorZona from "../RadioOperacion/selector_zona"
import SelectorZonaAvanzado from "../RadioOperacion/selector_zona_avanzado"
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional"
import { MdOpenInNew } from "react-icons/md"
import Costos from "../Costos"
import MediosBox from "../../subComponents/medios/medios"
import CaracteristicasVehiculo from "./requisitos/caracteristicas_vehiculo"
import { FiUser } from "react-icons/fi"
import { AiFillCar, AiFillMinusCircle } from "react-icons/ai"
import { BsSpeedometer, BsTruckFlatbed } from "react-icons/bs"
import SortBy from "../../subComponents/general/sort_by"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { FaRegTrashAlt } from "react-icons/fa"
import { IoCloseSharp } from "react-icons/io5"
import { BiTime } from "react-icons/bi"
import PrevisualizarPostulacion from "./previsualizar"
import BuscadorLocalidad from "../RadioOperacion/buscadorLocalidad"

const tiempo_intervalo = 10000
const disable_intervals = true

const ListadoViajes = (props)=> {
    const {
        condicion_default,
        exclude,
        condicion_forzed,
        showFilters,
        showCustomFieldsData,
        showCounters,
        showSelection,
        hideSearch,
        componentAction,
        loadingActions
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ intervalo, setIntervalo] = useState(false)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage()
    const [ filtroBusquedaMetaData, setFiltroBusquedaMetaData ] = useState({})
    const [ geoCercaBusqueda, setGeocercaBusqueda ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ condicionTiempoEntrega, setCondicionTiempoEntrega ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais = useSelector(state => state.pais)
    const trash                     = props.trash ? props.trash : false
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const tipo                      = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo                    = props.title ? props.title : 'registros'
    const module = 'postulaciones'
    const key_interfaz              = 'tabla_postulaciones'
    const key_esquema               = 'POSTULACIONES'
    const opciones_tiempo_entrega   = obtenerTiemposEntregaOpciones(pais)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const modulo = 'postulaciones'

    
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page', interfaz_usuario, modulo)


    const obtenerDatos = async (page, query, sort_config, stop_loading, id_geocerca, limit)=>{
        if(!stop_loading) setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        const id_busqueda = id_geocerca ? id_geocerca : geoCercaBusqueda?._id

        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                sort_config,
                id_geocerca: id_busqueda,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma,
                limit: limit ? limit : default_limit_page
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
        // const id_intervalo = setInterval(() => {
        //     if(disable_intervals) return false
        //     obtenerDatos(1, false, null, true)
        //   }, tiempo_intervalo);
        // setIntervalo(id_intervalo)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        const filtro_seleccionado = filtroBusqueda
        let cond = {}
        Object.keys(filtro_seleccionado).map(llave => {
            if(filtro_seleccionado[llave]['$in']){
                if(Array.isArray(filtro_seleccionado[llave]['$in']) !== false){
                    if(filtro_seleccionado[llave]['$in'].length > 0) cond[llave] = filtro_seleccionado[llave]
                }
            } else if(Array.isArray(filtro_seleccionado[llave]) !== false){
                    if(filtro_seleccionado[llave].length > 0){
                        cond[llave] = { $in: filtro_seleccionado[llave] }
                    }
            }
            
        })

        if(condicionTiempoEntrega) cond = {...cond, ...condicionTiempoEntrega}
        obtenerDatos(e, cond)

        clearInterval(intervalo)
        // const id_intervalo = setInterval(() => {
        //     if(disable_intervals) return false
        //     obtenerDatos(e, condicion_busqueda, null, true)
        //   }, tiempo_intervalo);
        // setIntervalo(id_intervalo)

    }
    
    const refrescarBusqueda = (filter) => {
        const filtro_seleccionado = filter ? filter : filtroBusqueda
        setPagina(1)
        let cond = {}
        Object.keys(filtro_seleccionado).map(llave => {
            if(filtro_seleccionado[llave]['$in']){
                if(Array.isArray(filtro_seleccionado[llave]['$in']) !== false){
                    if(filtro_seleccionado[llave]['$in'].length > 0) cond[llave] = filtro_seleccionado[llave]
                }
            } else if(Array.isArray(filtro_seleccionado[llave]) !== false){
                    if(filtro_seleccionado[llave].length > 0){
                        cond[llave] = { $in: filtro_seleccionado[llave] }
                    }
            }
            
        })

        if(condicionTiempoEntrega) cond = {...cond, ...condicionTiempoEntrega}
        obtenerDatos(1, cond)

        // clearInterval(intervalo)
        // const id_intervalo = setInterval(() => {
        //     if(disable_intervals) return false
        //     obtenerDatos(1, cond, null, true)
        //   }, tiempo_intervalo);
        // setIntervalo(id_intervalo)
    }

    const sortBy = (data, type) => {
        if(type === "sort_config"){

            const {
                sort_column,
                sort_order
            } = data
            setPagina(1)
            obtenerDatos(1, null, { sort_column, sort_order })

        } else if( type === "limit_pagination" ){

            setPagina(1)
            obtenerDatos(1, null, null, false, null, data.limit_page)            
            
        }
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Row gutter={15}>
                <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
                {/* <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col> */}
                <Col span="auto"><SortBy type="viajes" onChange={(data, type) => sortBy(data, type)} /></Col>
            </Row>
            
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const obtenerCondicionCompleta = (filter) => {
        let cond = condicion_default ? condicion_default : {}
        const filtro_seleccionado = filter ? filter : filtroBusqueda
        Object.keys(filtro_seleccionado).map(llave => {
            if(filtro_seleccionado[llave]['$in']){
                if(Array.isArray(filtro_seleccionado[llave]['$in']) !== false){
                    if(filtro_seleccionado[llave]['$in'].length > 0) cond[llave] = filtro_seleccionado[llave]
                }
            } else if(Array.isArray(filtro_seleccionado[llave]) !== false){
                    if(filtro_seleccionado[llave].length > 0){
                        cond[llave] = { $in: filtro_seleccionado[llave] }
                    }
                }
            
        })

        if(condicionTiempoEntrega) cond = {...cond, ...condicionTiempoEntrega}
        return cond
    }

    const descargarExcel = async () => {
        let cond = obtenerCondicionCompleta()
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/trips`,{
            method:'POST',
            body: JSON.stringify({
                condicion: cond
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            let cond = {}
            if(condicion_forzed) if(typeof condicion_forzed === "object") cond = {...cond, ...condicion_forzed}
            setCondicionBusqueda(cond)

            // clearInterval(intervalo)
            // const id_intervalo = setInterval(() => {
            //     if(disable_intervals) return false
            //     obtenerDatos(1, cond, null, true)
            // }, tiempo_intervalo);
            // setIntervalo(id_intervalo)
            
            return obtenerDatos(1, cond)
        }
        let condicion = {
            internal_id: parseInt(campoBusqueda)
        }
        if(condicion_forzed) if(typeof condicion_forzed === "object") condicion = {...condicion, ...condicion_forzed}
        setCondicionBusqueda(condicion);
        setPagina(1);

        // clearInterval(intervalo)
        // const id_intervalo = setInterval(() => {
        //     if(disable_intervals) return false
        //         obtenerDatos(1, condicion, null, true)
        // }, tiempo_intervalo);
        // setIntervalo(id_intervalo)

        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      }

      const removerFiltro = (key, value) => {
        setFiltroBusqueda(prev => {
            let actual  = {...prev}
            actual[key]['$in'] = actual[key]['$in'].filter(id => id !== value)
            if( actual[key] ) if(actual[key]['$in'].length < 1) delete actual[key]
            return actual
        })
        setFiltroBusquedaMetaData(prev => {
            let actual  = {...prev}
            actual[key] = actual[key].filter(v => v._id !== value)
            return actual
        })
    }

    const removerZonas = (key) => {
        setFiltroBusqueda(prev => {
            let actual  = {...prev}
            delete actual[key]
            return actual
        })
        setFiltroBusquedaMetaData(prev => {
            let actual  = {...prev}
            actual[key] = []
            return actual
        })
    }

      const mostrarMetaDataFiltros = (key, title) => {
        if(!filtroBusquedaMetaData[key]) return false
        if(!["ids_origin._id","ids_destination._id"].includes(key) ) return false

            if(filtroBusquedaMetaData[key].length < 1) return false
            return <Col md={8} className="mb-3">
                <Title level={5} className="mt-0 mb-0">{title}</Title>
                {
                    filtroBusquedaMetaData[key].map((e, i) => <Tag color="geekblue" style={{ marginRight: 10 }} className="mt-0 mb-0" key={`${e._id}.${i}`}>{e.titulo}  <IoCloseSharp className="hover" onClick={() => removerFiltro(key, e._id)} /></Tag> )
                }
                <Button size="small" style={{ color: "red" }} type="link" icon={<FaRegTrashAlt />} onClick={() => removerZonas(key)} >REMOVER TODO</Button>
            </Col>
        
      }
      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
                {/* <Row gutter={4}>
                    <Col md={6}>
                        <Form.Item className="mb-2">
                            <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Busca por número`} />
                        </Form.Item>
                    </Col>
                    <Col md={4}>
                        <Form.Item >
                            <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                        </Form.Item>
                    </Col>
                </Row> */}
                </Form>
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const deseleccionarTodos = (e) => {
        setRegistros([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const seleccionarRegistro = (data, action) => {
        deseleccionarTodos({ target: false })
        if(props.onSelect) props.onSelect(data, action)
        }

        const handleChangeSeleccion = (e) => {
            const { name } = e.target
            const valor = e.target.checked
            const i = conductores.findIndex(ve => ve._id === name )
            conductores[i].seleccionado = valor
            setRegistros([...[], ...conductores])
          }

          const seleccionarEspecial = (action) => {
            return seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true), action)
          }

      const accionesEspeciales = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true

        if(componentAction){
            return <div style={{ marginTop: 10, marginBottom: 10 }} onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>
                {componentAction.map(it => {
                    return <Button disabled={enable} loading={ loadingActions === true } size="small" style={{ marginRight: 10 }} onClick={() => seleccionarEspecial(it.label)} >{it.label}</Button>
                })}
            </div>
        }
        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button>
        </div>
      }

      const mostrarTiempo = (val, finalizado) => {
        const estilo={ width: "100%", fontsize: 10, textAlign: "center" }
        if(!val) return <TagSinDatos />
        switch (val) {
            case "si":
                return <div>
                    <Tag style={estilo} color="red">RETRASADO</Tag>
                    <Tag style={estilo} color="red"><b >{finalizado===true ? "FINALIZADO" : "PENDIENTE"}</b></Tag>
                </div>
            default:
                return <div>
                    <Tag style={estilo} color="green">A TIEMPO</Tag>
                    <Tag style={estilo} color="green"><b >{finalizado===true ? "FINALIZADO" : "PENDIENTE"}</b></Tag>
                </div>
        }
      }

      const mostrarTabla = () => {
        if(loadingRegistros) return <CargandoTabla />

        const component_id = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="id" />} render={(data) => {
                    return <div><Link to={`${rutas.trips.slug}/new/${data._id}`}>{data.internal_id ? data.internal_id : "VER DETALLES"}</Link></div>
        }} />
        const component_identificador = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="identificador" />} render={(data) => {
                    return <div>{data.identificador}</div>
        }} />

        const component_estado_app = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="estado" />} render={(orden) => {
            let titulo_estado           = ''
                let titulo_sub_estado       = ''
                let color_estado            = '#bebebe'

                if(orden.estado_entrega){
                    if(typeof orden.estado_entrega === 'object'){
                        if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                        if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                    }
                }
            
                if(orden.sub_estado_entrega){
                    if(typeof orden.sub_estado_entrega === 'object'){
                        if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                    }
                }

                let string_estado = `${titulo_estado} ${titulo_sub_estado}`
                if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"
                    return <div><p style={{ fontSize:9, fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white', textTransform: "uppercase" }} className="mb-2">{string_estado}</p></div>
        }} />
        
        const component_status = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(data) => {
                    return <div style={{ textTransform: "uppercase" }}>{stringByStatusTrip(data.status)}</div>
        }} />
        const component_planificacion = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="planificacion" />} render={(data) => {
                    let info = <TagSinDatos />
                    if(data?.planificacion?.routes) if(data.planificacion.routes.length > 0) info = data.planificacion.routes.sort((a,i) => a.distance - i.distance).map((route, i) => {
                        if(i > 0) return 
                        return <div key={`route-${i}`}>
                        <Paragraph className="mb-0 mb-0" style={{ fontSize: 12 }}><BiTime size={15} /> Tiempo <b style={{ fontWeight: "bold" }}>{formatTime(route.duration / 60)}</b></Paragraph>
                        <Paragraph className="mb-0 mb-0" style={{ fontSize: 12 }}><BsSpeedometer size={13} /> Distancia <b style={{ fontWeight: "bold" }}>{formatoMoneda(parseInt(route.distance / 1000))} Km.</b></Paragraph>
                        </div>
                    })
                    return <div style={{ textTransform: "uppercase" }}>
                        {info}
                    </div>
        }} />
        
        const component_tipo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo" />} render={(data) => {
                    return <div style={{ textTransform: "uppercase" }}>{data.tipo}</div>
        }} />
        const component_cliente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cliente" />} render={(data) => {
                    return <div style={{ textTransform: "uppercase" }}>{data.cliente ? data.cliente : "Sin información"}</div>
        }} />

        const component_notificar = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="notificar" />} render={(data) => {
                    return <div>
                        
                        <MostrarMoviles size="small" datos={data.moviles_adicionales} />
                        <Divider className="mt-0 mb-0" />
                        <MostrarEmails size="small" datos={data.emails_adicionales} />
                        
                    </div>
        }} />

        const component_ruta = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ruta" />} render={(data) => {
            return <div>{data.ruta?._id ? <Link to={`${rutas.rutas.slug}/${data.ruta?._id}`} target="_blank"><MdOpenInNew /></Link> : false } {data.ruta?.id}</div>
        }} />

        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />

        const component_updaedAt = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="updaedAt" />} render={(data) => {
            return <div>{fechaUTCATexto(data.updatedAt)}</div>
        }} />
        const component_retiro = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="fecha_retiro" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{fechaUTCATextoSimple(data.fecha_retiro_desde)}</Paragraph><Divider className="mt-0 mb-0" />
            </div>
        }} />
        const component_entrega = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="fecha_entrega" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{fechaUTCATextoSimple(data.fecha_entrega_desde)}</Paragraph><Divider className="mt-0 mb-0" />
                </div>
        }} />
        const component_retiro_hasta = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="fecha_retiro_hasta" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{fechaUTCATextoSimple(data.fecha_retiro_hasta)}</Paragraph>
            </div>
        }} />
        const component_entrega_hasta = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="fecha_entrega_hasta" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{fechaUTCATextoSimple(data.fecha_entrega_hasta)}</Paragraph>
                </div>
        }} />

        const component_puntos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="puntos" />} render={(data) => {
            let puntos = []
            if(data.puntos) if(Array.isArray(data.puntos) !== false) if(data.puntos.length > 0) {
                for( const point of data.puntos ){
                        if(point.geo_data) if(typeof point.geo_data === "object"){
                            let valor = point.geo_data.level3 ? point.geo_data.level3 : point.geo_data.level2
                            puntos.push(valor)
                        }
                }
            }
            return <div>
                    <Typography><b>{puntos.length}</b> {puntos.join(', ')}</Typography>
                </div>
        }} />
        
        const component_devuelto = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="devuelto" />} render={(data) => {
            return <div>{data.devuelto === true ? "SI": "NO"}</div>
        }} />

        const component_finalizado = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="finalizado" />} render={(data) => {
            return <div>{data.finalizado === true ? "SI": "NO"}</div>
        }} />
        
        const component_canal = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="canal" />} render={(data) => {
            return <div style={{ textTransform: "uppercase" }}>{data.canal ? data.canal : "WEB"}</div>
        }} />

        const component_fecha_devuelto = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="fecha_devuelto" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{fechaUTCATextoSimple(data.fecha_devuelto)}</Paragraph>
                </div>
        }} />

        const component_productos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="productos" />} render={(data) => {
            
            let productos = data.productos ? data.productos : []

            const visualizacionSimpleProductos = () => {

                return <div>
                    <Row gutter={15}>
                            <Col md={6} xs={24}>
                                <Paragraph className="mb-0 mt-0">Cantidad de bultos</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{formatoMoneda(data.bultos)} {data.unidad_medida}</Title>
                            </Col>
                            <Col md={6} xs={24}>
                                <Paragraph className="mb-0 mt-0">Ancho</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{formatoMoneda(data.ancho)} {data.unidad_medida}</Title>
                            </Col>
                            <Col md={6} xs={24}>
                                <Paragraph className="mb-0 mt-0">Alto</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{formatoMoneda(data.alto)} {data.unidad_medida}</Title>
                            </Col>
                            <Col md={6} xs={24}>
                                <Paragraph className="mb-0 mt-0">Largo</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{formatoMoneda(data.largo)} {data.unidad_medida}</Title>
                            </Col>
                            <Col md={6} xs={24}>
                                <Paragraph className="mb-0 mt-0">Peso</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{formatoMoneda(data.peso)} {data.unidad_medida_peso}</Title>
                            </Col>
                    </Row>
                </div>
        
            }

            
            const visualizacionAvanzadaProductos = () => {

                return <div>                
                    { productos.map((pro,i) => {
            
                    const contenido = <div key={`pro_${i}`} className="mb-3">
                        <Row gutter={15}>
                            <Col md={9}>
                                <Paragraph className="mb-0 mt-0">Descripción</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{pro.descripcion ? pro.descripcion : "Sin información"}</Title>
                            </Col>
                            <Col md={3} xs={24}>
                                <Paragraph className="mb-0 mt-0">Cantidad</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{formatoMoneda(pro.cantidad)}</Title>
                            </Col>
                            <Col md={3} xs={24}>
                                <Paragraph className="mb-0 mt-0">Ancho</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{pro.ancho} {pro.unidad_medida}</Title>
                            </Col>
                            <Col md={3} xs={24}>
                                <Paragraph className="mb-0 mt-0">Alto</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{pro.largo} {pro.unidad_medida}</Title>
                            </Col>
                            <Col md={3} xs={24}>
                                <Paragraph className="mb-0 mt-0">Largo</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{pro.largo} {pro.unidad_medida}</Title>
                            </Col>
                            <Col md={3} xs={24}>
                                <Paragraph className="mb-0 mt-0">Peso</Paragraph>
                                <Title level={4} className="mt-0 mb-0">{pro.peso} {pro.unidad_medida_peso}</Title>
                                {/* <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                                    <Select value={pro.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida_peso",i)} />
                                </Form.Item> */}
                            </Col>
                        </Row>
                        { (productos.length - 1) === i ? false : <Divider style={{ marginTop: 5, marginBottom: 5 }} /> }
                    </div>
                    
                    return contenido
                    })}
                </div>
            }

            const component_h = <div>
                { data.tipo_seleccion_productos === "avanzado" ? visualizacionAvanzadaProductos(data) : visualizacionSimpleProductos(data) }
            </div>
            
            return <div>
                <ModalPersonalizado typeView="modal"  version="v2" title="" component={component_h}  />
                </div>
        }} />
        const component_requisitos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="requisitos" />} render={(data) => {
            
            const requisitos = data.requisitos ? data.requisitos : []
            const caracteristicas = data.caracteristicas ? data.caracteristicas : {}
            const visualizarRequisitosPorTipo = (tipo) => {


                const filtrados = requisitos.filter(e => e.tipo === tipo)
        
                return <div>
                    <Row gutter={15}>
                    {
                        filtrados.map((req,i) => {
                            return <Col md={4} key={`req-${i}`}>
                                <Card size="small">
                                <Title level={5} className="m-0">{req.titulo}</Title>
                                <Paragraph level={5} className="m-0">{req.descripcion}</Paragraph>
                                </Card>
                            </Col>
                        })
                    }
                    </Row>
                </div>
            }

            
            const visualizarRequisitos = () => {

        
                const items = [
                    {
                        label: <div style={{ textAlign: "center" }}><AiFillCar style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL VEHÍCULO</Title></div>,
                        children: <div>
                            {visualizarRequisitosPorTipo('vehiculo')}
                        </div>
                    },
                    {
                        label: <div style={{ textAlign: "center" }}><FiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL CONDUCTOR</Title></div>,
                        children: <div>
                            {visualizarRequisitosPorTipo('conductor')}
                        </div>
                    },
                    {
                        label: <div style={{ textAlign: "center" }}><BsTruckFlatbed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CARACTERÍSTICA DEL VEHÍCULO</Title></div>,
                        children: <div>
                            <Paragraph >Solo los vehículos con las características que selecciones debajo podrán transportas esta carga</Paragraph>
                            <CaracteristicasVehiculo defaultData={caracteristicas} onChange={(data) => console.log(data)} />
                            {visualizarRequisitosPorTipo('caracteristica_vehiculo')}
                        </div>
                    },
                ].map((item,i) => ({ key: (i+1), ...item}))
        
                return <div>
                    <Title level={4} className="mt-0 mb-3">Requisitos</Title>
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            }
            const component_h = <div>{visualizarRequisitos()}</div>
            
            return <div>
                <ModalPersonalizado typeView="modal"  version="v2" title="" component={component_h}  />
                </div>
        }} />
        const component_documentos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="documentos" />} render={(data) => {
            const component_h = <div><MediosBox id_destino={data._id} /></div>
            return <div>
                <ModalPersonalizado typeView="modal"  version="v2" title="" component={component_h}  />
                </div>
        }} />
        const component_costos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="costos" />} render={(data) => {
            const condicion_costo = { id_target: data._id }
            const component_costos = <div>
                <Costos showCustomFieldsData={false} showCreateButton={false} showClientSelector={false} showImportButton={false} condicion={condicion_costo} default_payload={condicion_costo}  />
            </div>

            return <div>
                <ModalPersonalizado typeView="modal"  version="v2" title="" component={component_costos}  />
                </div>
        }} />
        const component_conductor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="conductor" />} render={(data) => {
            return <div>
                {data.conductor?.nombres} {data.conductor?.apellido_p} {data.conductor?._id ? <Link to={`${rutas.drivers.slug}/${data.conductor?._id}`} target="_blank"><MdOpenInNew /></Link> : false }
                </div>
        }} />
        const component_vehiculo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="vehiculo" />} render={(data) => {
            const component_h = <div></div>
            
            return <div>
                {data.vehiculo?.titulo} {data.vehiculo?._id ? <Link to={`${rutas.vehicles.slug}/${data.vehiculo?._id}`} target="_blank"><MdOpenInNew /></Link> : false }
                </div>
        }} />

        const component_fecha_entrega = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="fecha_finalizado" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{fechaUTCATextoSimple(data.fecha_finalizado)}</Paragraph>
                </div>
        }} />
        const component_cumplimiento = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cumplimiento" />} render={(data) => {
            return <div>
                <Paragraph className="mb-0">{mostrarTiempo(data.retrasado, data.finalizado)}</Paragraph>
                </div>
        }} />

        const acciones_botones = <Column title="Acciones" fixed="right" ellipsis render={(data) => {
            return <div>
                {/* <div className="mb-2"><Link to={`/trips/new/${data.id_orden}?tab=tracking`}><Button size="small" style={{ width: "100%" }} ><CgTrack /> VER TRACKING</Button></Link></div> */}
                <div className="mb-2"><PrevisualizarPostulacion id_registro={data._id} onCreate={() => obtenerDatos(1, false)} /></div>
          </div>
        }} />

          return <Table dataSource={conductores} pagination={false}  size="small" bordered scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="viajes" /> }}>
            {
                showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                    return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
                }} />
            }
            { encontrarLlaveValorEnEsquema("id", interfaz_usuario[ key_interfaz ]) === false ? false : component_id }
            { encontrarLlaveValorEnEsquema("identificador", interfaz_usuario[ key_interfaz ]) === false ? false : component_identificador }
            { encontrarLlaveValorEnEsquema("estado", interfaz_usuario[ key_interfaz ]) === false ? false : component_estado_app }
            { encontrarLlaveValorEnEsquema("cumplimiento", interfaz_usuario[ key_interfaz ]) === false ? false : component_cumplimiento }
            {/* { encontrarLlaveValorEnEsquema("tipo", interfaz_usuario[ key_interfaz ]) === false ? false : component_tipo } */}
            { encontrarLlaveValorEnEsquema("ruta", interfaz_usuario[ key_interfaz ]) === false ? false : component_ruta }
            { encontrarLlaveValorEnEsquema("status", interfaz_usuario[ key_interfaz ]) === false ? false : component_status }
            { encontrarLlaveValorEnEsquema("planificacion", interfaz_usuario[ key_interfaz ]) === false ? false : component_planificacion }
            { encontrarLlaveValorEnEsquema("puntos", interfaz_usuario[ key_interfaz ]) === false ? false : component_puntos }
            { encontrarLlaveValorEnEsquema("devuelto", interfaz_usuario[ key_interfaz ]) === false ? false : component_devuelto }
            { encontrarLlaveValorEnEsquema("finalizado", interfaz_usuario[ key_interfaz ]) === false ? false : component_finalizado }
            { encontrarLlaveValorEnEsquema("cliente", interfaz_usuario[ key_interfaz ]) === false ? false : component_cliente }
            { encontrarLlaveValorEnEsquema("notificar", interfaz_usuario[ key_interfaz ]) === false ? false : component_notificar }
            { encontrarLlaveValorEnEsquema("productos", interfaz_usuario[ key_interfaz ]) === false ? false : component_productos }
            {/* { encontrarLlaveValorEnEsquema("requisitos", interfaz_usuario[ key_interfaz ]) === false ? false : component_requisitos } */}
            { encontrarLlaveValorEnEsquema("documentos", interfaz_usuario[ key_interfaz ]) === false ? false : component_documentos }
            { encontrarLlaveValorEnEsquema("costos", interfaz_usuario[ key_interfaz ]) === false ? false : component_costos }
            { encontrarLlaveValorEnEsquema("conductor", interfaz_usuario[ key_interfaz ]) === false ? false : component_conductor }
            { encontrarLlaveValorEnEsquema("vehiculo", interfaz_usuario[ key_interfaz ]) === false ? false : component_vehiculo }
            { encontrarLlaveValorEnEsquema("fecha_devuelto", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha_devuelto }
            { encontrarLlaveValorEnEsquema("fecha_finalizado", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha_entrega }
            { encontrarLlaveValorEnEsquema("fecha_retiro", interfaz_usuario[ key_interfaz ]) === false ? false : component_retiro }
            { encontrarLlaveValorEnEsquema("fecha_retiro_hasta", interfaz_usuario[ key_interfaz ]) === false ? false : component_retiro_hasta }
            { encontrarLlaveValorEnEsquema("fecha_entrega", interfaz_usuario[ key_interfaz ]) === false ? false : component_entrega }
            { encontrarLlaveValorEnEsquema("fecha_entrega_hasta", interfaz_usuario[ key_interfaz ]) === false ? false : component_entrega_hasta }
            { encontrarLlaveValorEnEsquema("canal", interfaz_usuario[ key_interfaz ]) === false ? false : component_canal }
            { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }
            { encontrarLlaveValorEnEsquema("updaedAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_updaedAt }
            {acciones_botones}
      </Table>
      }

      const agregarFiltro = (key, value) => {

        let previas     = []
        let values      = []
        if(key === "ids_origin._id"){
            
            previas = filtroBusqueda[key] ? filtroBusqueda[key]['$in'] : []
            values = [...previas, ...value.map(v => v._id) ]
            setFiltroBusquedaMetaData(prev => {
                let actual = {...prev}
                if(!actual[key]) actual[key] = []
                let nuevos_items = []
                for( const val of value ){
                    const i = actual[key].findIndex(it => it._id === val._id)
                    if(i < 0) nuevos_items.push(val)
                }
                actual[key] = [ ...actual[key], ...nuevos_items ]
                return actual
            })

        } else if( key === "ids_destination._id" ){
            
            previas = filtroBusqueda[key] ? filtroBusqueda[key]['$in'] : []
            values = [...previas, ...value.map(v => v._id) ]

            setFiltroBusquedaMetaData(prev => {
                let actual = {...prev}
                if(!actual[key]) actual[key] = []
                let nuevos_items = []
                for( const val of value ){
                    const i = actual[key].findIndex(it => it._id === val._id)
                    if(i < 0) nuevos_items.push(val)
                }
                actual[key] = [ ...actual[key], ...nuevos_items ]
                return actual
            })
        }
        setFiltroBusqueda(prev => {
            let actual = {...prev}
            if(value.length > 0){
                actual[key] = values.length > 0 ? { $in: values } :  { $in: value.map(v => v?._id ? v?._id : v) }
            } else {
                delete actual[key]
            }
            refrescarBusqueda(actual)
            return actual
        })
      }

      const agregarFiltroEmbedido = (value, llave, llave_busqueda) => {
        return setFiltroBusqueda(prev => {
            let actual = {...prev}
            let llave_valor = "_id"
            if(llave_busqueda) llave_valor = llave_busqueda
            if(value.length < 1) {
                delete actual[llave]
            } else {
                actual[llave] = [...value.map(e => e[llave_valor])]
            }

            return actual
        })
      }

      const handleChangeTiempo = (e) => {
        if(!e) return setCondicionTiempoEntrega(false)
        const query = obtenerQueryTiempoEntrega(e)
        return setCondicionTiempoEntrega(query)
      }

      const resetFilters = () => {
        setFiltroBusqueda({})
        setFiltroBusquedaMetaData({})
        setKeyInputFilter(key_input_filter + 1)
        setPagina(1)
        obtenerDatos(1, condicion_default ? condicion_default : {})

        // clearInterval(intervalo)
        // const id_intervalo = setInterval(() => {
        //     if(disable_intervals) return false
        //     obtenerDatos(1, condicion_default ? condicion_default : {}, null, true)
        // }, tiempo_intervalo);
        // setIntervalo(id_intervalo)
    }

    const buscarPorGeocerca = (data) => {
        setGeocercaBusqueda(data)

        // clearInterval(intervalo)
        // const id_intervalo = setInterval(() => {
        //     obtenerDatos(e, condicion_busqueda, null, true, data?._id)
        //   }, tiempo_intervalo);
        // setIntervalo(id_intervalo)

    }

    const agregarUbicacion = (data, key) => console.log(data, key)

      const mostrarFiltrosBusqueda = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div className="mt-0">
            <Form layout="vertical">
            <Row gutter={10}>
                {/* <Col md={6} >
                    <SelectorGlobal key={key_input_filter} titulo="Requisito solicitados" condicion={{ }} isMulti={true} module="requisitos/tipo" key_label="titulo" key_value="_id" onChange={(data) => agregarFiltroEmbedido(data, 'requisitos._id', '_id')} />
                </Col>
                 */}
                <Col md={8}>
                    <Form.Item label="Origen de la carga">
                    {/* <SelectorZonaAvanzadoInternacional titleLabel="AGREGAR " onSelected={(data) => agregarFiltro('ids_origin._id', [data])} /> */}
                    <BuscadorLocalidad 
                        // defaultValue={campo.ids_origin ? campo.ids_origin.map(e => ({ ...e, value: e._id })) : []} 
                        onChange={(data) => agregarFiltro('ids_origin',data)} 
                    />
                    </Form.Item>
                </Col>
                <Col md={8}>
                    <Form.Item label="Destinos de la carga">
                    <BuscadorLocalidad 
                        // defaultValue={campo.ids_origin ? campo.ids_origin.map(e => ({ ...e, value: e._id })) : []} 
                        onChange={(data) => agregarFiltro('ids_destination',data)} 
                    />
                    {/* <SelectorZonaAvanzadoInternacional titleLabel="AGREGAR " onSelected={(data) => agregarFiltro('ids_destination._id', [data])} /> */}
                    </Form.Item>
                </Col>
                <Col md={4}>
                    <Paragraph style={{ marginBottom: 8 }} >Toca para actualizar</Paragraph>
                    <Button onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={4} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
            </Form>
            <Row>
            {/* {mostrarMetaDataFiltros('ids_origin._id',  'Origen de la carga')} */}
            {/* {mostrarMetaDataFiltros('ids_destination._id',  'Destinos dela carga')} */}
            </Row>
        </div>
      }

      const mostrarContadores = () => {

        const component = <ContadoresVehiculos onFilter={(condition) => {
            setPagina(1)
            setCondicionBusqueda(condition)
            obtenerDatos(1, condition)

            // clearInterval(intervalo)
            // const id_intervalo = setInterval(() => {
            //     if(disable_intervals) return false
            //     obtenerDatos(1, condition, null, true)
            // }, tiempo_intervalo);
            // setIntervalo(id_intervalo)
            
        }} />

        return showCounters === true ? <Col><ModalPersonalizado typeView="modal" slug="distrubion" component={component} /></Col> : false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="viaje" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

    const mostrarRegistros = () => {

        return <div>
            <Divider />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {buscador()}
                    {mostrarFiltrosBusqueda()}
                </Col>
                <Col md={24}>
                    <Row gutter={15} className="mb-3">
                    {mostrarContadores()}
                    {/* {mostrarDatosPersonalizados()} */}
                    </Row>
                    {paginacion(default_limit_page, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {mostrarContadores()}
                    {buscador()}
                    {paginacion(default_limit_page, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoViajes