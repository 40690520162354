import { useCallback, useEffect, useState } from "react"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import TiposServicioSelector from "../ordenes/tipos_servicio/selector"
import { set } from "date-fns"
import { validateEmail } from "../../lib/helpers/helpers"
import { BsFillTrashFill } from "react-icons/bs"
import { AiFillPlusCircle } from "react-icons/ai"
import SelectorEstados from "../estados_carga/selector"
import { FaAngleDown, FaAngleRight, FaRegEnvelope, FaWhatsapp } from "react-icons/fa"
// import CrearNotificacionesMasivas from "./notificacion_masiva"
import LineaTiempo from "../../components/LineaTiempo"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Collapse, Form, Input, Row, Spin, Tabs } from "antd"
import BuscadorUsuarios from "../usuarios/buscador"
import Structure from "../../components/Structure"
import { modulos_notificaciones } from "./data"
import Title from "antd/es/typography/Title"
import SelectorGlobal from "../general/selector"
import InputPais from "./inputPais"

const ConfiguracionNotificaciones = (props) => {
    const estructura = {
        tipos_servicio: []
    }

    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ tipoServicioSeleccionado, setTipoServicioSeleccionado ] = useState(null)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'
    const sub_tipo = "estados-carga"

    useEffect(() => {
        obtenerDatos(sub_tipo)
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "notificaciones",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                // for( const modulo of estructura.modulos){
                //     const i = res.detalles.modulos.findIndex(mod => mod.slug === modulo.slug)
                //     if(i > -1){
                //         let crear_modulo = res.detalles.modulos[i]
                //         Object.keys(modulo).forEach(key => {
                //             if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                //         })
                //         console.log(crear_modulo)
                //         res.detalles.modulos[i] = crear_modulo
                //     } else {
                //         res.detalles.modulos.push(modulo)
                //     }
                // }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);


    const handleChangeStatus = (e,i_movulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_movulo].options = e
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const onChangeValue = (data) => {
        console.log(data)
        setTipoServicioSeleccionado(data)
    }

    const agregarTipoServicio = () => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            const i = actual.tipos_servicio.findIndex(ts => ts._id === tipoServicioSeleccionado._id)
            if(i > -1) return { ...actual }
            actual.tipos_servicio.push({
                _id: tipoServicioSeleccionado._id,
                nombre: tipoServicioSeleccionado.title,
                emails_adicionales: [],
                moviles_adicionales: []
            })
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const anadirEmailAdicional = (i) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.tipos_servicio[i].emails_adicionales) actual.tipos_servicio[i].emails_adicionales = []
            actual.tipos_servicio[i].emails_adicionales.unshift({
                    email: ''
            })
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        
        })
    }
    
    const anadirMovilAdicional = (i) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.tipos_servicio[i].moviles_adicionales) actual.tipos_servicio[i].moviles_adicionales = []
            actual.tipos_servicio[i].moviles_adicionales.unshift({
                pais_codigo: '',
                movil: ''
            })
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        
        })
    }

    const removerEmail = (i, i_modulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].emails_adicionales.splice(i,1)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }
    
    const removerMovil = (i, i_modulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].moviles_adicionales.splice(i,1)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const handleChangeEmail = (e, i, i_modulo) => {
        const { name, value } = e.target
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].emails_adicionales[i][name] = value
            actual.tipos_servicio[i_modulo].emails_adicionales[i].valid = validateEmail(value)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }
    
    const handleChangeMovil = (e, i, i_modulo) => {
        const { name, value } = e.target
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].moviles_adicionales[i][name] = value
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const mostrarEmailsAdicionales = (emails_adicionales, i_modulo) => {
        if(!Array.isArray(emails_adicionales)) return false
        if(!emails_adicionales) return false
        return <div>
            {
                emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Form.Item label="Email">
                                <Input placeholder="Escribe aquí tu email" className={`form-control mb-3 ${field.valid === false ? 'is-invalid' : ''}`} pos={i}  name="email" value={field.email} onChange={(e) => handleChangeEmail(e,i,i_modulo)} />
                                <b className="text-danger hover" onClick={() => removerEmail(i, i_modulo)}><BsFillTrashFill /> REMOVER</b>
                        </Form.Item>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = (moviles_adicionales, i_modulo) => {
        if(!Array.isArray(moviles_adicionales)) return false
        if(!moviles_adicionales) return false
        return <div>
            <Form layout="vertical">
            {
                moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Form.Item label="Móvil">
                    <Row gutter={15}>
                        <Col xs={8}>
                            {/* <select className='form-control' defaultValue="" pos={i} name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e, i, i_modulo)}> */}
                            <InputPais i={i} field={field} handleChangeMovil={handleChangeMovil} i_modulo={i_modulo} />
                        </Col>
                        <Col xs={16}>
                            <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' pos={i}  name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e, i, i_modulo)} />
                        </Col>
                    </Row>
                    </Form.Item>
                    <b className="text-danger hover" onClick={() => removerMovil(i, i_modulo)}><BsFillTrashFill /> REMOVER</b>
                    </div>
                })
            }
            </Form>
        </div>
    }

    const removerModulo = (i) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio.splice(i,1)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const handleChangeModulo = (e, modulo_slug, opcion_slug, key_search, key_value) => {
        let key_find = key_search ? key_search : "options"
        let keyVal = key_value ? key_value : "email"
        setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.modulos) actual.modulos = modulos_notificaciones

            let i_modulo = actual.modulos.opciones.findIndex(modulo => modulo.slug === modulo_slug)
            if(i_modulo < 0){
                const i_modulo_modelo = modulos_notificaciones.opciones.findIndex(modulo => modulo.slug === modulo_slug)
                actual.modulos.opciones.push(modulos_notificaciones.opciones[i_modulo_modelo])
                i_modulo = actual.modulos.opciones.length - 1
            }

            let iopcion = actual.modulos.opciones[i_modulo].opciones.findIndex(opcion => opcion.slug === opcion_slug)
            if(iopcion < 0){
                const iopcion_modelo = modulos_notificaciones.opciones[i_modulo].opciones.findIndex(opcion => opcion.slug === opcion_slug)
                actual.modulos.opciones[i_modulo].opciones.push(modulos_notificaciones.opciones[i_modulo].opciones[iopcion_modelo])
                iopcion = actual.modulos.opciones[i_modulo].opciones.length - 1
            }
            
            if(!actual.modulos.opciones[i_modulo].opciones[iopcion][key_find]) actual.modulos.opciones[i_modulo].opciones[iopcion][key_find] = []
            actual.modulos.opciones[i_modulo].opciones[iopcion][key_find] = e.map(user => ({ value: user[keyVal], label: `${user[keyVal]} ${user.nombres ? user.nombres : ""}` }))
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const findValue = (modulo_slug, opcion_slug, key_search) => {
        const key_find = key_search ? key_search : 'options'
        if(!estructuraActual.modulos) return []
        let i_modulo = estructuraActual.modulos.opciones.findIndex(modulo => modulo.slug === modulo_slug)
        if(i_modulo < 0) return []
        let iopcion = estructuraActual.modulos.opciones[i_modulo].opciones.findIndex(opcion => opcion.slug === opcion_slug)
        if(iopcion < 0) return []
        return estructuraActual.modulos.opciones[i_modulo].opciones[iopcion][key_find]
    }
    
    const mostrarMantenedorModulos = () => {

        const items = modulos_notificaciones.opciones.map((modulo,imodulo) => {

            const label = <div className="pl-3 pr-3 pb-0 hover">
            <Title level={3} className="mb-0 mt-0" style={{ fontWeight: "bold" }}>{modulo.label} · <b style={{ fontWeight: "lighter", fontSize: 14 }}>{modulo.opciones.length} Notificaciones disponibles</b></Title>
            </div>

            const children = <div className="p-2" >
            {
                modulo.opciones.map((opcion, iopcion) => {

                    let defaultValue = []
                    let defaultValuePhone = []
                    const encontrar = findValue(modulo.slug, opcion.slug)
                    const encontrar_phone = findValue(modulo.slug, opcion.slug, 'phones')
                    if(Array.isArray(encontrar)) if(encontrar.length > 0){
                        defaultValue = encontrar
                    }
                    if(Array.isArray(encontrar_phone)) if(encontrar_phone.length > 0){
                        defaultValuePhone = encontrar_phone
                    }
                    
                    return <div className="mb-3" key={`${imodulo}-${iopcion}`}>
                        <Card className="p-2">
                        <Title level={5} className='d-block mb-0 mt-0'><FaAngleRight /> <b>{opcion.label}</b></Title>
                        <Row gutter={15}>
                            <Col>
                                <Title level={5} className="mb-2 mt-0"><b><FaRegEnvelope /> Emails</b></Title>
                                <BuscadorUsuarios defaultValue={defaultValue} key_identifier="email" key={`modulo-${imodulo}-${iopcion}`} full_payload={true} multi={true} label="Selecciona los usuarios" onChange={(e) => handleChangeModulo(e, modulo.slug, opcion.slug)} />
                            </Col>
                            <Col>
                                <Title level={5} className="mb-2 mt-0"><b><FaWhatsapp style={estilo_last_mile.icon} /> Whatsapp</b></Title>
                                <BuscadorUsuarios filterValues="phone" labelType="phone" defaultValue={defaultValuePhone} key_identifier="phone" key={`modulo-${imodulo}-${iopcion}-phone`} full_payload={true} multi={true} label="Selecciona los usuarios" onChange={(e) => handleChangeModulo(e, modulo.slug, opcion.slug, "phones", "phone")} />
                            </Col>
                        </Row>
                        
                        </Card>
                    </div>
                })
            }
            </div>

            return {
                key: imodulo,
                label,
                children
            }
        })
        return <div>
            <Title level={5} className="mt-3">Toca el módulo y configura los emails correspondientes a la notificación</Title>
            <Collapse accordion items={items} />
        </div>
    }

    const mostrarMantenedorTipoServicio = () => {
        return <div>
            <Row>
            {
                estructuraActual.tipos_servicio.map((modulo,imodulo) => {
                    let defaultValue = []
                    if(Array.isArray(modulo.options)) if(modulo.options.length > 0){
                        defaultValue = modulo.options.map(e => e._id)
                    }
                    return <Col xs={12} key={`modulo-${imodulo}`}  className="mb-3">
                        <Card className="p-3">
                        <Row gutter={15} align="center">
                            <Col><Title level={4} className="mb-0 mt-0" style={{ fontWeight: "bold" }}>{modulo.nombre}</Title></Col>
                            <Col className="text-right"><Button size="sm" variant="outline-danger" onClick={() => removerModulo(imodulo)}>REMOVER</Button> </Col>
                        </Row>
                        <hr className="mt-3 mb-3"/>

                        <SelectorEstados tipo_dato="subestados" key={`modulo-${imodulo}`} defaultValue={defaultValue} isMulti={true} full_payload={true} onChange={(e) => handleChangeStatus(e, imodulo)} />

                        <Title level={5} className="mb-0 mt-0">Emails adicionales</Title>
                        <label className='form-control-label d-block'><b className="text-success hover" onClick={() => anadirEmailAdicional(imodulo)}><AiFillPlusCircle/> AGREGAR NUEVO</b></label>
                        {mostrarEmailsAdicionales(modulo.emails_adicionales, imodulo)}
                        <hr />
                        <Title level={5} className="mb-0 mt-0">Teléfonos adicionales</Title>
                        <label className='form-control-label d-block'><b className="text-success hover" onClick={() => anadirMovilAdicional(imodulo)}><AiFillPlusCircle /> AGREGAR NUEVO</b></label>
                        {mostrarMovilesAdicionales(modulo.moviles_adicionales, imodulo)}
                        </Card>
                    </Col>
                })
            }
        </Row>
        </div>
    }

    const tipoServicioComponent = <div className="p-3">
        <Form layout="vertical">
        <Row gutter={15} className="mb-3">
            <Col md={12}>
                <Title className="mb-2" level={5}>Selecciona un tipo de servicio</Title>
                <Row gutter={15}>
                    <Col md={8}>
                    <SelectorGlobal titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChange={(data) => onChangeValue(data)} />
                    </Col>
                    <Col md={4}><Button className="w-100" disabled={tipoServicioSeleccionado ? false : true } size="sm" onClick={() => agregarTipoServicio()}>SELECCIONAR</Button> </Col>
                </Row>
            </Col>            
        </Row>
        </Form>
        {mostrarMantenedorTipoServicio()}
    </div>

    const modulos = <div className="p-3">
    {mostrarMantenedorModulos()}
    </div>

    const lineaTiempo = <div className="p-3"><LineaTiempo /></div>

    const itemsTabs = [
        { key: "1", label: <Title level={5} className="mt-0 mb-0">Por tipo de servicio</Title>, children: tipoServicioComponent },
        { key: "2", label: <Title level={5} className="mt-0 mb-0">Por módulos</Title> , children: modulos },
        // { key: "3", label: "Crear notificaciones", children: <div>{/* <CrearNotificacionesMasivas /> */ }</div> },
        { key: "4", label: <Title level={5} className="mt-0 mb-0">Línea de tiempo</Title>, children: lineaTiempo }

    ]

    const render = () => {
        return <div>
        <Row gutter={15}>
            <Col xs={12}><p>Actualiza la visualización por módulo y por campos </p></Col>
            <Col xs={12} className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spin size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        
        <Tabs defaultActiveKey="1" items={itemsTabs} />
        
    </div>
 
    }

    if(loading) return <PantallaCargando />

    return <Structure component={render()} />
}

export default ConfiguracionNotificaciones