import { Button, Card, Col, Collapse, Divider, Drawer, Form, Input, Modal, Row, Select, Space, Switch, Tabs, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { url_images, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled, SaveOutlined } from "@ant-design/icons";
import { TbSelect } from "react-icons/tb";
import { FaCloudUploadAlt, FaHeading, FaRegEnvelope, FaRegTrashAlt } from "react-icons/fa";
import { BsCalendarDate, BsCursorText, BsInputCursorText } from "react-icons/bs";
import { RxDividerHorizontal } from "react-icons/rx";
import { RiCheckboxLine } from "react-icons/ri";
import { MdOutlineDateRange, MdOutlineEmail, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BiText } from "react-icons/bi";
import CargandoTabla from "./cargando";
import { elementos_interior_vehiculos, elementos_exterior_vehiculos, elementos_seguridad, elementos_sistema_electrico, main_data } from "./data";
import Title from "antd/es/typography/Title";
import ButtonGroup from "antd/es/button/button-group";
import Paragraph from "antd/es/typography/Paragraph";
import ListadoEntradas from "./entradas_list";
import CrearTarea from "./crear_tarea";
import ListadoTareas from "./listado_tareas";
import { GrAdd } from "react-icons/gr";
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai";
import { validateEmail } from "../../lib/helpers/helpers";


// const elementos_exterior_vehiculos = [
//     {
//       key_module: "exterior",
//       slug: "select",
//       title: "Llantas",
//       label: "Llantas",
//       icon: <img src={`${url_images}/icons/driver.svg`} style={{ width: 20 }} />,
//       values: [
//         { value: "Buen estado", slug: "buen-estado" },
//         { value: "Desgaste leve", slug: "desgaste-leve" },
//         { value: "Desgaste severo", slug: "desgaste-severo" },
//         { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
//       ]
//     },
//     {
//         key_module: "exterior",
//       slug: "select",
//       title: "Puertas",
//       label: "Puertas",
//       values: [
//         { value: "Funcionando", slug: "funcionando" },
//         { value: "Dificultad para abrir", slug: "dificultad-para-abrir" },
//         { value: "Dificultad para cerrar", slug: "dificultad-para-cerrar" },
//         { value: "Requiere ajuste", slug: "requiere-ajuste" }
//       ]
//     },
//     {
//         key_module: "exterior",
//       slug: "select",
//       title: "Faros delanteros",
//       label: "Faros delanteros",
//       values: [
//         { value: "Funcionando", slug: "funcionando" },
//         { value: "Luz baja", slug: "luz-baja" },
//         { value: "Luz alta", slug: "luz-alta" },
//         { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
//       ]
//     },
//     {
//         key_module: "exterior",
//       slug: "select",
//       title: "Luces traseras",
//       label: "Luces traseras",
//       values: [
//         { value: "Funcionando", slug: "funcionando" },
//         { value: "Luz baja", slug: "luz-baja" },
//         { value: "Luz alta", slug: "luz-alta" },
//         { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
//       ]
//     },
//     {
//         key_module: "exterior",
//       slug: "select",
//       title: "Retrovisores",
//       label: "Retrovisores",
//       values: [
//         { value: "Intactos", slug: "intactos" },
//         { value: "Grietas leves", slug: "grietas-leves" },
//         { value: "Grietas severas", slug: "grietas-severas" },
//         { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
//       ]
//     },
//     {
//       key_module: "exterior",
//       slug: "select",
//       title: "Intermitentes",
//       label: "Intermitentes",
//       values: [
//         { value: "Funcionando", slug: "funcionando" },
//         { value: "Luz baja", slug: "luz-baja" },
//         { value: "Luz alta", slug: "luz-alta" },
//         { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
//       ]
//     }
//   ];
  
const EditarNuevoCampoPersonalizado = (props) => {
    const {
        id_registro
    } = props
    const elementos = [
        {
            id: "",
            slug: "input",
            title: "Campo de texto",
            icon: <BsInputCursorText size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "email",
            title: "Email",
            icon: <MdOutlineEmail size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "texto",
            title: "Texto",
            icon: <BiText size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "title",
            title: "Título",
            icon: <FaHeading size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "archivo",
            title: "Carga",
            icon: <FaCloudUploadAlt size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "divisor",
            title: "Divisor",
            icon: <RxDividerHorizontal size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "select",
            title: "Selector",
            icon: <TbSelect size={main_data.sizeIcon}  />
        },
        {
            id: "",
            slug: "checkbox",
            title: "Checkbox",
            icon: <RiCheckboxLine  size={main_data.sizeIcon} />
        },
        {
            id: "",
            slug: "datepicker",
            title: "Datepicker",
            icon: <MdOutlineDateRange size={main_data.sizeIcon}  />
        }
    ]

    

    const [ campos, setCampos ] = useState([])
    const [ indexSeleccionado, setIndexSeleccionado ] = useState(-1)
    const [open, setOpen] = useState(false)
    const [ campo, setCampo ] = useState(false)
    const [ registro, setRegistro ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const slug_module                   = main_data.slug_module

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${slug_module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setRegistro(res)
                if(res.formulario) if(Array.isArray(res.formulario)) setCampos(res.formulario)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const agregarOpcionSelector = () => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values.push({})
            return actual
        }) 
    }

    const eliminarValorOpcion = (i) => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values.splice(i, 1)
            return actual
        })
    }

    const handleChangeValorSelector = (e, i) => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values[i].value = e.target.value
            return actual
        })
    }

    const componentByType = (campo) => {
        switch (campo.slug) {
            case 'input':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Input placeholder={campo.example_value} addonBefore={<BsCursorText />}  type="text" />
                </Form.Item>
            case 'email':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Input addonBefore={<FaRegEnvelope /> } type="email" />
                    </Form.Item>
            case 'texto':
                return <Paragraph className="mt-0 mb-0">{campo.label}</Paragraph>
            case 'title':
                return <Title level={3} className='mb-0 mt-0'>{campo.label}</Title>
            case 'archivo':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Input type="file" />
                    </Form.Item>
            case 'divisor':
                return <Divider />
            case 'select':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Select>
                    {
                        campo.values.map((opcion, i) => {
                            return <Select.Option key={`opcion-${i}`} value={opcion.value}>{opcion.value}</Select.Option>
                        })
                    }
                </Select>
                    </Form.Item>
            case 'checkbox':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Switch />
                    </Form.Item>
            case 'datepicker':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                <Input type="date" />
                </Form.Item>
            case 'rangepicker':
                return <Form.Item className="mb-0" help={campo.help_text} label={campo.label} required={campo.required === true}>
                    <Input />
                </Form.Item>
            default:
                return <div>Este componente no es editable.</div>
        }
    }

    const renderModalContent = (componenteSeleccionado) => {
        if (!componenteSeleccionado) return null;

        const input_media_allow = <Switch className="mt-3" checkedChildren="SOLICITAR CARGA DE ARCHIVO" unCheckedChildren="NO SOLICITAR CARGA DE ARCHIVO" checked={componenteSeleccionado.allow_upload_media === true} onChange={(e) => handleChange({ target: { name: "allow_upload_media", value: e } })} />


        switch (componenteSeleccionado.slug) {
            case 'input':
                return <div className="mb-3">
                <Form.Item className="mb-3" label="Valor de ejemplo">
                    <Input type="text" value={componenteSeleccionado.example_value} name="example_value" onChange={handleChange} />
                </Form.Item>
                {/* <Form.Item className="mb-3" label="Valor predeterminado">
                    <Input type="text" value={componenteSeleccionado.default_value} name="default_value" onChange={handleChange} />
                    </Form.Item> */}
                {input_media_allow}
            </div>
            case 'select': 
            return <div className="mb-3">
                    <Form.Item className="mb-3" label="Valor de ejemplo">
                        <Input type="text" value={componenteSeleccionado.example_value} name="example_value" onChange={handleChange} />
                    </Form.Item>
                    <Form.Item className="mb-3" label="Opciones">
                    {
                        componenteSeleccionado.values.map((opcion, i) => {
                            return <Input addonBefore={<FaRegTrashAlt className="hover" onClick={() => i ? eliminarValorOpcion(i) : false } />} className="mb-3" placeholder="Escribe un valor" value={opcion.value} onChange={(e) => handleChangeValorSelector(e, i)} />
                        })
                    }
                    </Form.Item>
                    <Button style={{ width: "100%" }} onClick={() => agregarOpcionSelector()}>AGREGAR OPCIÓN</Button>
                    {input_media_allow}
                </div>
            case 'checkbox':
                return <div className="mb-3">
                    <Switch checkedChildren="ACTIVO POR DEFECTO" unCheckedChildren="INACTIVO POR DEFECTO" checked={componenteSeleccionado.default_checked === true} onChange={(e) => handleChange({ target: { name: "default_checked", value: e } })} />
                    {input_media_allow}
                </div>
            default:
                return <div>Este componente no es editable.</div>;
        }
    }


    const guardarCambios = async () => {
        if(!registro.title) return messageApi.error("Escribe un título")
        if(registro.title.length < 3) return messageApi.error("Escribe un título válido")
        const record = { ...registro }
        record.formulario = campos


        if(record.emails_notificacion) if(Array.isArray(record.emails_notificacion)) {
            const emails_invalidos = record.emails_notificacion.filter(e => !validateEmail(e.email))
            if(emails_invalidos.length) return messageApi.error("Hay emails inválidos: " + emails_invalidos.map(e => e.email).join(', '))
        }

        setLoadingSave(true)
        return fetch(`${urlapi}/${slug_module}`,{
            method:'PUT',
            body: JSON.stringify(record),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    }

    const handleCancelModal = () => {
        setIndexSeleccionado(-1)
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        return setRegistro({...{}, ...{}})
      };

      const handleChange = (e) => {
        const { name, value } = e.target
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado][name] = value
            return actual
        })
    }
    
    const handleChangeRegistro = (e) => {
        const { name, value } = e.target
        return setRegistro(prev => {
            let actual = { ...prev }
            actual[name] = value
            return actual
        })
    }

    const mostrarModal = () => {
        let objeto = indexSeleccionado > -1 ? campos[indexSeleccionado] : {}

        return <Modal
            title="Editar Componente"
            open={indexSeleccionado > -1}
            onOk={handleCancelModal}
            footer={[]}
            onCancel={handleCancelModal}
        >
            <Form layout="vertical">
            <Form.Item className="mb-3" label="Etiqueta">
            <Input type="text" value={objeto.label} name="label" onChange={handleChange} />
            </Form.Item>
            <Form.Item className="mb-3" label="Texto de ayuda">
            <Input type="text" value={objeto.help_text} name="help_text" onChange={handleChange} />
            </Form.Item>
            {renderModalContent(objeto)}
            <Switch checkedChildren="CAMPO OBLIGATORIO" unCheckedChildren="CAMPO OPCIONAL" checked={objeto.required === true} onChange={(e) => handleChange({ target: { name: "required", value: e } })} />
            </Form>
        </Modal>
    }

    const verConfiguracion = (i) => {
        setIndexSeleccionado(i)
    }

    const eliminarCampo = (i) => {
        setCampos(prev => {
            let actual = [ ...prev ]
            actual.splice(i, 1)
            return actual
        })
    }

    const agregarElemento = (elm) => {
        const nuevo_elemento = {
            key_module: elm.key_module,
            id: "",
            label: elm.label ? elm.label : "",
            example_value: "",
            default_value: "",
            values : elm.values ? elm.values : [{}],
            min_max: 0,
            default_checked: false,
            max_val: 0,
            slug : elm.slug,
            id_elemento : elm.id,
            required: false
        }
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual.push(nuevo_elemento)
            return actual
        })
    }

    const anadirEmailAdicional = () => {
        return setRegistro(prev => {
            let actual = { ...prev }
            if(!actual.emails_notificacion) actual.emails_notificacion = []
            actual.emails_notificacion.unshift({
                email: ''
            })
            return actual
        })
    }

    const move = (pos, direction) => {

        setCampos(prev => {
            let actual = [ ...prev ]
            let temp = actual[pos]
            if(direction === 'up'){
                if(pos){
                    actual[pos] = actual[pos - 1]
                    actual[pos - 1] = temp
                }
            } else {
                if(pos < actual.length - 1){
                    actual[pos] = actual[pos + 1]
                    actual[pos + 1] = temp
                }
            }
            return [ ...actual ]
        })
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        return setRegistro(prev => {
            let actual = { ...prev }
            const pos = parseInt(e.target.getAttribute('pos'))
            actual.emails_notificacion[pos][name] = value
            actual.emails_notificacion[pos].valid = validateEmail(value)
    
            return actual
        })
    }

    const removerEmail = (i) => {
        return setRegistro(prev => {
            let actual = { ...prev }
            actual.emails_notificacion.splice(i,1)
            return actual
        }) 
    }


    const mostrarEmailsAdicionales = () => {
        if(!registro.emails_notificacion) return false
        return <div>
            {
                registro.emails_notificacion.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    
    const formulario = () => {

        const basics = <Row gutter={10}>
            {
                elementos.map((elm,i) => {
                    return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                        <Card size="small" onClick={() => agregarElemento(elm)} >
                        {elm.icon}
                        <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                        </Card>
                    </Col>
                })
            }
        </Row>

        const interior_elements = <Row gutter={10}>
            {
                elementos_interior_vehiculos.map((elm,i) => {
                    return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                        <Card size="small" style={{height: '100%'}} onClick={() => agregarElemento(elm)} >
                        <img src={elm.icon} alt="icon" style={{width: 35}}/>
                        <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                        </Card>
                    </Col>
                })
            }
        </Row>
        const exterior_elements = <Row gutter={10}>
            {
                elementos_exterior_vehiculos.map((elm,i) => {
                    return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                        <Card size="small" style={{height: '100%'}} onClick={() => agregarElemento(elm)} >
                        <img src={elm.icon} alt="icon" style={{width: 35}}/>
                        <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                        </Card>
                    </Col>
                })
            }
        </Row>
        
        const electric_elements = <Row gutter={10}>
            {
                elementos_sistema_electrico.map((elm,i) => {
                    return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                        <Card size="small" style={{height: '100%'}} onClick={() => agregarElemento(elm)} >
                        <img src={elm.icon} alt="icon" style={{width: 35}}/>
                        <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                        </Card>
                    </Col>
                })
            }
        </Row>
        const security_elements = <Row gutter={10}>
            {
                elementos_seguridad.map((elm,i) => {
                    return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                        <Card size="small" style={{height: '100%'}} onClick={() => agregarElemento(elm)} >
                        <img src={elm.icon} alt="icon" style={{width: 35}}/>
                        <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                        </Card>
                    </Col>
                })
            }
        </Row>

        const items_ = [
            {
                label: <Title level={4} className="mb-0 mt-0">Básicos</Title>,
                children: basics
            },
            {
                label: <Title level={4} className="mb-0 mt-0">Interior de vehículo</Title>,
                children: interior_elements
            },
            {
                label: <Title level={4} className="mb-0 mt-0">Exterior de vehículo</Title>,
                children: exterior_elements
            },
            {
                label: <Title level={4} className="mb-0 mt-0">Sistema eléctrico</Title>,
                children: electric_elements
            },
            {
                label: <Title level={4} className="mb-0 mt-0">Sistema de seguridad</Title>,
                children: security_elements
            },
        ]

        const box_edit = <Row gutter={15}>
        <Col md={12}>
        <Card >
        <Title className="mt-0" level={3}>Formulario</Title>
        {
            campos.map((campo, i) => {
                return <Card className="hover" key={`campo-${i}`} size="small" style={{ marginBottom: 10 }} >
                    <Row>
                        <Col>
                            <Row>
                                <Col><Tag className="mt-0 mb-2" style={{ textTransform: "uppercase" }} level={5}>{campo.slug} {campo.required === true ? <b style={{ color: "red" }}>*</b> : false } </Tag></Col>
                                <Col>{ campo.label ? <Title className="mt-0 mb-3" level={5}>{campo.label}</Title> : false }</Col>
                            </Row>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                        <ButtonGroup>
                            <Button size="small" onClick={() => move(i,'up')}><MdOutlineKeyboardArrowUp /> </Button>
                            <Button size="small" onClick={() => move(i,'down')}><MdOutlineKeyboardArrowDown /> </Button>
                            <Button size="small" onClick={() => verConfiguracion(i)}>CONFIGURAR</Button>
                            <Button size="small" icon={<FaRegTrashAlt />} onClick={() => eliminarCampo(i)}>ELIMINAR</Button>
                        </ButtonGroup>
                    </Card>
            })
        }
            {mostrarModal()}
        </Card>
        </Col>
        <Col md={12}>
        <Card>
        <Title className="mt-0" level={3}>Elementos</Title>
        <Collapse accordion items={items_.map((e,i) => ({ key:`e-${i}`, ...e }))} />
        </Card>
        </Col>
        </Row>
        
        const box_visualization = <div>
        {/* <Title className="mt-0" level={3}>Formulario</Title> */}
        <Form layout="vertical">
        {
            campos.map((campo, i) => {
                return <div className="mb-3" key={`campo-${i}`} style={{ marginBottom: 10 }} >
                    {componentByType(campo)}
                    </div>
            })
        }
            {mostrarModal()}       
        </Form>
        </div>

        let items = [
            {
                label: "Editar formulario",
                children: box_edit
            },
            {
                label: "Previsualizar",
                children: box_visualization
            },
            {
                label: "Base de datos",
                children: <div><ListadoEntradas formulario={campos} /> </div>
            },
            {
                label: "Tareas",
                children: <div>
                    <div style={{ marginBottom: 15 }}><CrearTarea id_formulario={id_registro} /></div>
                    <ListadoTareas formulario={campos} condicion_default={{ id_formulario: id_registro }} />
                </div>
            },
            {
                label: "Configuración",
                children: <div>
                    <Title level={4} className="mt-0 mb-3">Notificar nuevas entradas</Title>
                    <Paragraph>Recibe un correo electrónico cada vez que alguien complete el formulario, añade los emails </Paragraph>
                    <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                    {mostrarEmailsAdicionales()}
                </div>
            },
        ]

        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input name="title" defaultValue={registro.title} onChange={handleChangeRegistro} />
                </Form.Item>
            </Form>

            <Tabs items={items.map((e,i) => ({ ...e, label: <div><Title className="mb-0 mt-0" level={4}>{e.label}</Title></div>, key: i }))} />

        </div>
    }
    
    return <div>
        <Button  className="mb-3" type="link" onClick={showModal}>EDITAR</Button>
        <Drawer title="EDITAR REGISTRO" onClose={handleCancel} open={isModalOpen} width={"95%"} extra={
          <Space>
            <Button disabled={loading} onClick={handleCancel}>CANCELAR</Button>
            <Button loading={loading || loadingSave} onClick={() => guardarCambios()} type="primary"  >GUARDAR</Button>
          </Space>
        } >
        {formulario()}
        </Drawer>
      {contextHolder}
    </div>
}

export default EditarNuevoCampoPersonalizado