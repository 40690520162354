import { Alert, Button, Card, Checkbox, Col, Collapse, Divider, Form, Input, Modal, Row, Select, Tabs, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import Title from "antd/es/typography/Title";
import { fechaATextoExperimental, fechaUTCATextoSimple, filterOption, formatYMD, formatYMD_UTC } from "../../lib/helpers/helpers";
import { campos_fecha, modulo, requeridos, stringByStatusSolicitud } from "./data";
import { FaCircleInfo, FaFileInvoice, FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import { FaAddressCard, FaAngleRight, FaCheckSquare, FaChevronRight, FaHistory, FaRegListAlt, FaRegStickyNote, FaRegTrashAlt, FaSearch } from "react-icons/fa";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional";
import { GiHandTruck } from "react-icons/gi";
import { DateTime } from "luxon";
import Paragraph from "antd/es/typography/Paragraph";
import { tipos_disponibilidad } from "../Disponibilidades/data";
import ListadoVehiculos from "../Vehiculos/listado";
import { MdOutlineMarkUnreadChatAlt, MdOutlinePlace } from "react-icons/md";
// import MapaDetalleDisponibilidad from "./mapa_detalles_disponibilidad";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import VisualizarViaje from "../Viajes/visualizar";
import AccionesSolicitud from "./accionesSolicitud";
import ChatLayout from "./chat";
import TagSinDatos from "../../subComponents/general/tag_sin_datos";
import VisualizarCrearCotizacion from "../Cotizaciones/visualizar_crear";
import SelectorGlobal from "../../subComponents/general/selector";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { obtenerIdentificadorConductor, obtenerIdentificadorVehiculo } from "../../lib/helpers/conductores";
import { LuTruck } from "react-icons/lu";
import ListadoNotificaciones from "../Notificaciones/listado";
import EdicionViaje from "../Viajes/edicionViajeSolicitud";
import DetalleViajeCliente from "../Viajes/publico/detalle_publico";
import { CgTrack } from "react-icons/cg";
import CalificarViaje from "../Viajes/calificar";

const VerSolicitudRapido = (props) => {
    const {
        id_registro,
        hideButton
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ vehiculos, setVehiculos ] = useState([])
    const [ conductor, setConductor ] = useState(null)
    const [ vehiculo, setVehiculo ] = useState(null)
    const [ loading, setLoading ] = useState(true)
    const [ loadingAsignacion, setLoadingAsignacion] = useState(false)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ origenes, setOrigenes ] = useState([])
    const [ detallesViaje, setDetallesViaje ] = useState(false)
    const [ destinos, setDestinos ] = useState([])
    const dispatch = useDispatch()
    const module = modulo

  
    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/disponibilidades/solicitudes-externas?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
                if(res.vehiculos) setVehiculos(res.vehiculos)
                if(res.origenes) if(Array.isArray(res.origenes)) setOrigenes(res.origenes)
                if(res.destinos) if(Array.isArray(res.destinos)) setDestinos(res.destinos)
                if(res.orden){
                    setDetallesViaje(res.orden)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const asignarConductor = async () => {
        if(!conductor) return messageApi.error("Selecciona un conductor")
        if(!detallesViaje?.viaje) return messageApi.error("No se ha podido obtener los detalles del viaje")
        setLoadingAsignacion(true)
        return fetch(`${urlapi}/disponibilidades/solicitudes-externas/asignacion?id=${detallesViaje?.viaje?._id}&idconductor=${conductor?._id}&idsolicitud=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Conductor asignado exitosamente")
                setDetallesViaje(prev => {
                    let actual = { ...prev }
                    actual.viaje.conductor = conductor
                    return actual
                })
            }
            return setLoadingAsignacion(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingAsignacion(false)
        })
    }

    const asignarVehiculo = async () => {
        if(!vehiculo) return messageApi.error("Selecciona un conductor")
        if(!detallesViaje?.viaje) return messageApi.error("No se ha podido obtener los detalles del viaje")
        setLoadingAsignacion(true)
        return fetch(`${urlapi}/disponibilidades/solicitudes-externas/asignacion-vehiculo?id=${detallesViaje?.viaje?._id}&idvehiculo=${vehiculo?._id}&idsolicitud=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Asignado exitosamente")
            }
            return setLoadingAsignacion(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingAsignacion(false)
        })
    }


    const guardarCambios = async () => {
        let guardar_campo = {...{}, ...campo}
        let faltantes = []
        requeridos.map(field => {
            if(!guardar_campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        setLoadingSave(true)
        guardar_campo.vehiculos = vehiculos
        
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(guardar_campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };


    useEffect(() => {
        if(hideButton === true) showModal()
    }, [])

    
    const handleOk = () => {
        return guardarCambios()
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

      const getDateKeyFromHourKey = (key) => {
        if(key === "hora_carga_desde") return "fecha_carga_desde"
        if(key === "hora_carga_hasta") return "fecha_carga_hasta"
        if(key === "hora_descarga_desde") return "fecha_descarga_desde"
        if(key === "hora_descarga_hasta") return "fecha_descarga_hasta"
        return false
      }

      const convertDateByMomentDate = (key, val) => {
        if(key === "fecha_carga_desde") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_carga_hasta") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_descarga_desde") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_descarga_hasta") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
      }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            const date_key = getDateKeyFromHourKey(name)
            if(date_key){
                const val_date = actual[date_key]
                if(val_date){
                    if(value){
                        const horario = value.split(':')
                        actual[date_key] = DateTime.fromISO(val_date).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    } else {
                        actual[date_key] = convertDateByMomentDate(date_key, val_date)
                    }
                }
            } else {
                if(campos_fecha.includes(name)){
                    if(value){
                        if(actual[name]){
                            const parsear = DateTime.fromISO(actual[name]).toUTC()
                            const fecha = DateTime.fromISO(value).toUTC().set({ hour: parsear.hour, minute: parsear.minute }).toISO()
                            actual[name] = fecha
                        } else {
                            const nueva_fecha = DateTime.fromISO(value).toUTC()
                            actual[name] = convertDateByMomentDate(nueva_fecha, value)
                        }
                    } else {
                        actual[name] = ''
                    }
                } else {
                    actual[name] = value
                }
            }
            return {...{}, ...actual}
        })
    }


    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const mostrarMantenedorFechas = () => {
        let carga_desde = ''
            let hora_carga_desde = ''
            let carga_hasta = ''
            let hora_carga_hasta = ''

            let descarga_desde = ''
            let hora_descarga_desde = ''
            let descarga_hasta = ''
            let hora_descarga_hasta = ''

            if(campo.fecha_carga_desde) carga_desde = fechaATextoExperimental(campo.fecha_carga_desde)
            if(campo.fecha_carga_desde) hora_carga_desde = DateTime.fromISO(campo.fecha_carga_desde).toFormat('HH:mm')
            if(campo.fecha_carga_hasta) carga_hasta = fechaATextoExperimental(campo.fecha_carga_hasta)
            if(campo.fecha_carga_hasta) hora_carga_hasta = DateTime.fromISO(campo.fecha_carga_hasta).toFormat('HH:mm')



            if(campo.fecha_descarga_desde) descarga_desde = fechaATextoExperimental(campo.fecha_descarga_desde)
            if(campo.fecha_descarga_desde) hora_descarga_desde = DateTime.fromISO(campo.fecha_descarga_desde).toFormat('HH:mm')
            if(campo.fecha_descarga_hasta) descarga_hasta = fechaATextoExperimental(campo.fecha_descarga_hasta)
            if(campo.fecha_descarga_hasta) hora_descarga_hasta = DateTime.fromISO(campo.fecha_descarga_hasta).toFormat('HH:mm')

            const leyenda = <Card size="small" className="mb-3" bordered={false} ><FaCircleInfo style={{ verticalAlign: "middle" }} /> Esta es la disponibilidad que el cliente solicitante obtuvo en los resultados, es la configurada por ti en el módulo de disponibilidades</Card>

            return <Col md={24}>
                <Row gutter={10}>
                <Col md={12}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={3} className="mt-0 mb-2"><GiHandTruck style={{ verticalAlign: "middle" }} /> Rango de carga</Title>
                        {/* {leyenda} */}
                            <Row gutter={15}>
                                <Col xs={12}>
                                    <Paragraph className="mb-0">Desde</Paragraph>
                                    <Paragraph style={{ fontWeight: "bold", fontSize: 12 }} level={4} className="mt-0 mb-2">{carga_desde}</Paragraph>
                                </Col>
                                <Col xs={12}>
                                    <Paragraph className="mb-0">Hasta</Paragraph>
                                    <Paragraph style={{ fontWeight: "bold", fontSize: 12 }} level={4} className="mt-0 mb-2">{carga_hasta}</Paragraph>
                                </Col>
                            </Row>
                    </Col>
                    <Col md={12}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={3} className="mt-0 mb-2"><FaTruckRampBox style={{ verticalAlign: "middle" }} /> Rango de descarga</Title>
                            {/* {leyenda} */}
                            <Row gutter={15}>
                                <Col xs={12}>
                                <Paragraph className="mb-0">Desde</Paragraph>
                                <Paragraph style={{ fontWeight: "bold", fontSize: 12 }} level={4} className="mt-0 mb-2">{descarga_desde}</Paragraph>
                                </Col>
                                <Col xs={12}>
                                <Paragraph className="mb-0">Hasta</Paragraph>
                                <Paragraph style={{ fontWeight: "bold", fontSize: 12 }} level={4} className="mt-0 mb-2">{descarga_hasta}</Paragraph>
                                </Col>
                            </Row>
                    </Col>
            </Row>
            </Col>
    }

    const mostrarUbicaciones = (key) => {
        if(!campo[key]) return false
        if(campo[key].length < 1) return false
        return <div>
            {
                campo[key].map((ubi,i) => <Title level={4} key={`ubi-${i}`} className="mt-0 mb-2"><FaChevronRight style={{ verticalAlign: "middle" }} /> {ubi.titulo}</Title>)
            }
        </div>
    }

    const agregarUbicacion = (data, key) => {
        if(!data) return
        setCampo(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = []
            const i = actual[key].findIndex(e => e._id === data._id)
            if(i < 0) actual[key].push({ _id: data._id, titulo: data.titulo, slug: data.slug })
            return actual
        })
    }

    const remover = (i) => {
        setVehiculos(prev => {
            let actual = [...prev]
            actual.splice(i,1)
            return actual
        })
    }
    
    const mostrarVehiculos = () => {
        return <div>
            <Divider className="mt-3 mb-3" />
            {
                vehiculos.map((veh,iv) => {
                    return <div key={`veh-${iv}`}>
                        <Row gutter={15}>
                            <Col md={18}><Title level={5} className="mt-0 mb-2"><FaTruckFront style={{ verticalAlign: "middle" }} /> {veh.identificador}</Title></Col>
                            <Col md={6}><Button size="small" icon={<FaRegTrashAlt />} onClick={() => remover(iv)} >REMOVER</Button> </Col>
                        </Row>
                        { (iv+1) !== vehiculos.length ? <Divider className="mt-3 mb-3" /> : false}
                    </div>
                })
            }
        </div>
    }

    const actualizarData = (data) => {
        if(!data) return 
        return setCampo(prev => {
            let actual = {...prev}
            actual.status = data.status
            return actual
        })
    }

    const asignacionConductor = () => {
        if(campo.status !== "aceptada-proveedor") return <Alert showIcon type="warning" description="La solicitud aún no ha sido aceptada por el proveedor, una vez hecho podrás asignar el conductor" className="mb-3" />
        return <div>
            <Row gutter={15}>
                    <Col xs={12}>
                    <Card size="small" className="mb-3">
                        <Title level={4} className="mt-0 mb-2">Conductor actualmente asignado </Title>
                        {
                            detallesViaje?.viaje?.conductor ? <div>
                                <Title level={3} className="mb-0 mt-0">{detallesViaje?.viaje?.conductor?.nombres} {detallesViaje?.viaje?.conductor?.apellido_p} {detallesViaje?.viaje?.conductor?.apellido_m}</Title>
                                <Title level={3} className="mb-0 mt-0"><IoPhonePortraitOutline style={{ verticalAlign: "middle" }} /> {detallesViaje?.viaje?.conductor?.phone}</Title>
                            </div> : <TagSinDatos />
                        }
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card size="small" className="mb-3">
                        <Title level={4} className="mt-0 mb-2">Asignar conductor al viaje </Title>
                        <Row gutter={15}>
                            <Col xs={14}><SelectorGlobal key={1} fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }}  module="conductores" key_label="nombres" key_value="_id" onChange={(data) => setConductor(data)} /></Col>
                            <Col xs={8}>
                            <Typography className="mt-0 mb-2">Asigna al App Conductor</Typography>
                            <Button type="primary" disabled={!conductor} loading={loadingAsignacion} onClick={() => asignarConductor()}>ASIGNAR CONDUCTOR</Button></Col>
                        </Row>
                        </Card>
                    </Col>


                    <Col xs={24}>
                        <Card size="small" className="mb-3">
                        <Title level={4} className="mt-0 mb-2"><LuTruck /> Asignar vehículo al viaje </Title>
                        <ModalSeleccionVehiculo showSelection={true} defaultValue={detallesViaje?.viaje?.vehiculo ? { ...detallesViaje?.viaje?.vehiculo, titulo: obtenerIdentificadorVehiculo(detallesViaje?.viaje?.vehiculo) } : null} onSelect={data => setVehiculo(data)} onRemove={() => setVehiculo(false)} />
                            <Button type="primary" className="mt-3" disabled={!vehiculo} loading={loadingAsignacion} onClick={() => asignarVehiculo()}>ASIGNAR VEHÍCULO</Button>
                        </Card>
                    </Col>
                </Row>
        </div>
    }

    const detallesSolcitud = () => {
        return <Form layout="vertical">
        <Row gutter={15} >
        
            <Col xs={24}>
                <div className="mb-3 mt-3"><AccionesSolicitud status={campo.status} id_solicitud={campo._id} type="proveedor" onChange={(data) => actualizarData(data)} /> </div>
            </Col>
            <Col xs={24}>
                <Card size="small" style={{ backgroundColor: estilo_moving_truck.colors.primary }}>
                    <Row gutter={15}>
                    <Col md={2}>
                        <Paragraph className="mb-0" style={{ color: "white" }}>ID</Paragraph>
                        <Title level={4} style={{ textTransform: "uppercase", color: "white" }} className="mt-0 mb-0">{campo.id}</Title>
                    </Col>  
                    <Col md={4}>
                        <Paragraph className="mb-0" style={{ color: "white" }}>Estado</Paragraph>
                        <Title level={4} style={{ textTransform: "uppercase", color: "white" }} className="mt-0 mb-0">{stringByStatusSolicitud(campo.status)}</Title>
                    </Col>  
                    <Col md={8}>
                        <Paragraph className="mb-0" style={{ color: "white" }}>Empresa</Paragraph>
                        <Title level={4} className="mt-0 mb-0" style={{ color: "white" }}>{campo.empresa?.razon_social}</Title>
                    </Col>
                        <Col md={4}>
                            <Title style={{ color: "white" }} level={4} className="mb-0 mt-0">Tipo de carga</Title>
                            <Typography style={{ color: "white" }} className="mt-0 mb-0">{campo.tipo_carga?.title}</Typography>
                        </Col>
                        <Col md={4}>
                            <Title style={{ color: "white" }} level={4} className="mb-0 mt-0">Valor de carga</Title>
                            <Typography style={{ color: "white" }} className="mt-0 mb-0">{campo.moneda_carga} {campo.valor_carga}</Typography>
                        </Col>
                    </Row>
                </Card>

                <Title level={4} className="mb-0 mt-3"><FaRegStickyNote /> Descripción de la carga</Title>
                { campo.descripcion_carga ? <Typography className="mt-0 mb-0">{campo.descripcion_carga}</Typography> : <TagSinDatos />}

            </Col>
            {/* <Col md={12}>
            <Paragraph className="mb-0">Tipo</Paragraph>
            <Title level={3} style={{ textTransform: "uppercase" }} className="mt-0 mb-0">{tipo_disponibilidad}</Title>
            </Col>                     */}
            
            {mostrarMantenedorFechas()}
            <Col md={24}>
            <Divider className="mt-3 mb-3" />
                <Row gutter={10}>
                    <Col md={12}>
                        <Title level={4} className="mt-0 mb-2"><MdOutlinePlace color="orange" style={{ verticalAlign: "middle" }} /> Ubicación de carga</Title>
                        {mostrarUbicaciones('ids_origin')}
                        
                    </Col>
                    <Col md={12}>
                    <Title level={4} className="mt-0 mb-2"><MdOutlinePlace color={estilo_moving_truck.colors.blue} style={{ verticalAlign: "middle" }} /> Ubicación de descarga</Title>
                        {mostrarUbicaciones('ids_destination')}
                        {/* <MapaDetalleDisponibilidad origenes={origenes} destinos={destinos} />  */}
                    </Col>
                </Row>
            </Col>
            <Col md={24}>
                <Divider className="mt-3 mb-3" />
                <Title level={4} className="mt-0 mb-2">Detalles de la orden </Title>
                {asignacionConductor()}
                { detallesViaje ? <VisualizarViaje orden={detallesViaje} /> : false }
            </Col>
        </Row>
        
    </Form>
    }

    const requerimientosCliente = () => {
        if(!campo.exigencias) return <TagSinDatos />

        const esquema = campo.exigencias?.esquema
        const agrupar = {}
        let groupos = []
        let configSeleccionada = []

        if(campo.exigencias?.seleccionada) if(Array.isArray(campo.exigencias.seleccionada)) configSeleccionada = campo.exigencias.seleccionada

        if(esquema?.groups) if(Array.isArray(esquema.groups)) {
            groupos = esquema.groups
            esquema.groups.forEach((g) => {
                agrupar[g.key] = esquema.options.filter((o) => o.group === g.key);
            });
        }

        return <div>
            <Title level={4} className="mt-0 mb-2">Requerimientos seleccionados por el cliente</Title>
            <Typography className="mt-0 mb-3">Estos son los requerimientos que el cliente solicitante ha seleccionado en su solicitud como requisitos mínimos que debes cumplir, si aceptas la solicitud aceptas cumplir con cada uno de los requerimientos a continuación</Typography>
                    {groupos.map((group) => (
                        <div key={group.key}>
                            <Title className="mb-0 mt-0" level={4}><FaAngleRight /> {group.label}</Title>
                            <Card size="small" className="mt-3 mb-3">
                                {agrupar[group.key].map((option) => {
                                    if(!configSeleccionada.includes(option.label)) return false
                                    return <Typography level={6} key={option.label} className="mt-0 mb-0"><FaCheckSquare /> {option.label}</Typography>
                                })}
                            </Card>
                        </div>
                    ))}
        </div>
    }
    
    const formulario = () => {
        if(loading) return <CargandoTabla />

        const detalles_solicitud = detallesSolcitud()

        let ids_vehiculos = []

        if(campo) if(campo.ids_vehiculos) if(Array.isArray(campo.ids_vehiculos)) ids_vehiculos = campo.ids_vehiculos
        
        const items = [
            {
                label: <Title level={4} className="mt-0 mb-0"><FaSearch /> Detalles de la solicitud</Title>,
                children: detalles_solicitud
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><FaTruckFront /> Vehículos solicitados</Title>,
                children: ids_vehiculos.length > 0 ? <ListadoVehiculos condicion_default={{ _id: { $in: ids_vehiculos }}} /> : <TagSinDatos />
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><MdOutlineMarkUnreadChatAlt /> Chat</Title>,
                children: <div><ChatLayout id_target={campo._id} /></div>
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><FaRegListAlt /> Requerimientos del cliente</Title>,
                children: <div>{requerimientosCliente()}</div>
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><FaFileInvoice /> Cotización</Title>,
                children: <div><VisualizarCrearCotizacion id_solicitud={campo._id} /> </div>
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><FaHistory /> Bitácora</Title>,
                children: <div><ListadoNotificaciones hideColumns={["driver","phone"]} hideFilters={true} condicion_default={{ id_target: campo._id }} /></div>
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><FaAddressCard /> Remitente y Destinatario</Title>,
                children: <div><EdicionViaje viaje={detallesViaje} /></div>
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><CgTrack /> Tracking</Title>,
                children: <div>
                    <DetalleViajeCliente id={detallesViaje?.viaje?._id} />
                </div>
            },
            {
                label: <Title level={4} className="mt-0 mb-0"><CgTrack /> Calificación</Title>,
                children: <div>
                    <CalificarViaje id={detallesViaje?.viaje?._id} />
                </div>
            },
        ].map((item,i) => ({ ...item, key: i }))

        return <div>
            <Tabs items={items} />
        </div>
    }
    
    return <div>
        <Button  className="m-0" size="small" onClick={showModal}>DETALLES</Button>
        <Modal 
            width="80%"
            title="Detalles" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default VerSolicitudRapido