import { Card, Checkbox, Col, Form, Row } from "antd"

const CheckBoxes = (props) => {
    const {
        list,
        onChange,
        defaultValues
    } = props
    const defaultValues_ = Array.isArray(defaultValues) ? defaultValues : []

    const manejarSeleccion = (e, label) => {
        onChange(e.target.checked, label);
    };


    return <div>
        <Form>
            <Row gutter={15}>
            {
               list.map((option,i) => {
                    return <Col md={5} key={`a-${i}`}>
                        <Card size="small">
                        <Form.Item className="mb-0">
                            <Checkbox
                                        key={option.label}
                                        defaultChecked={defaultValues_.includes(option.label)}
                                        // checked={configSeleccionada.includes(option.label)}
                                        onChange={(e) => manejarSeleccion(e, option.label)}
                                    >
                                        {option.label}
                                    </Checkbox>
                        </Form.Item>
                        </Card>
                    </Col>
               }) 
            }
            </Row>
        </Form>
    </div>
}

export default CheckBoxes