import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Pagination, Row, Tabs, message } from 'antd';
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import ListadoViajes from "./listado";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import HelperModal from "../../subComponents/general/helperModal";
import ModaAsignacionViajes from "./modal_asignacion";

const Postulaciones = (props) => {
    const [ loading, setLoading ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ showModalAsignacion, setShowModalAsignacion ] = useState(false)
    const [ registrosSeleccionados, setRegistrosSeleccionados ] = useState([])
    const [ messageApi, contextHolder] = message.useMessage();
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const module = 'viajes'
    const crearViaje = async ()=>{
        setLoadingCreacion(true)
        return fetch(`${urlapi}/${module}/draft`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                window.location = `${rutas.trips.slug}/new/${res._id}`
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const activarMovimientoRuta = (viajes) => {
        console.log({viajes})
        setShowModalAsignacion(true)
        return setRegistrosSeleccionados(viajes)
    }

    const eliminarMultiples = async (viajes)=>{
        setLoading(true)

        const seleccionados = viajes.map(e => e._id)
        return fetch(`${urlapi}/${module}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const componentes_accion = [
        {
            label: "MOVER A UNA RUTA",
            action: activarMovimientoRuta
        },
        {
            label: "ELIMINAR",
            action: eliminarMultiples
        }
    ]
    

    const onSelect = (datos,action) => {
        if(!action) return
        const i = componentes_accion.findIndex(it => it.label === action)
        console.log({ i, action })
        if(i > -1){
            const funcion = componentes_accion[i].action
            funcion(datos)
        }
    }

    const render = () => {
        return <div>
            <ModaAsignacionViajes show={showModalAsignacion} registros={registrosSeleccionados} onCancel={() => {
                setShowModalAsignacion(false)
                return setRegistrosSeleccionados([])
            }} />
            <Card>
            <Title className="mb-2 mt-0">Postulaciones</Title>
            <Row gutter={15}>
              {/* <Col span="auto"><Button style={{ marginRight: 10 }} type="primary" size="small" loading={loadingCreacion} onClick={() => crearViaje()} ><PlusCircleFilled /> CREAR NUEVO</Button></Col> */}
              {/* <Col span="auto"><Button style={{ marginRight: 10 }} size="small"><Link to={rutas.import_trips.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col> */}
              <Col span="auto"><HelperModal tipo="postulaciones" style="modal" /></Col>
            </Row>
            <ListadoViajes loadingActions={loading} onSelect={(data,action) => onSelect(data,action)} showSelection={true} componentAction={componentes_accion} showCustomFieldsData={true} showCounters={false} showFilters={true} condicion_default={{ }} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Postulaciones