import { useEffect, useRef, useState } from "react"
import Select from 'react-select';
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";

const BuscadorUsuarios = (props) => {
    const {
        key,
        full_payload,
        defaultValue,
        key_identifier,
        labelType,
        filterValues
    } = props
    const [ usuarios, setUsuarios ] = useState([{ value:'all_access', label:'Todos los permisos'}])
    const [ loading, setLoading ] = useState(true)
    const label = props.label ? props.label : 'Usuarios'
    const typeLabel = labelType ? labelType : 'email'
    const multi = props.multi ? true : false
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    let default_key_identifier = key_identifier ? key_identifier : "_id"
    const fullPayload = full_payload === true
    const dispatch = useDispatch()
    const selectRef = useRef()

    const handleChangeSelect = (e) => {
        let data = e
        if(fullPayload) {
            if(multi === true ){
                if(e){
                    const opciones = []
                    e.map(valor => {
                        const i = usuarios.findIndex(u => u[key_identifier] === valor.value)
                        if(i > -1) opciones.push(usuarios[i])
                    })
                    data = opciones
                }
            } else {
                const i = usuarios.findIndex(u => u[key_identifier] === e.value)
                if(i > -1) data = usuarios[i]
            }
        }
        if(props.onChange) return props.onChange(data)
        return
    }

    const obtenerUsuarios = async () => {
        return fetch(`${data.urlapi}/usuarios/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setUsuarios(res)
                setTimeout(() => {

                    // if(defaultValue) {
                    //     if(multi === true){
                    //         if(selectRef?.current) selectRef.current.setValue( res.filter(u => defaultValue.includes(u[default_key_identifier])) )
                    //     }   
                    // }
                }, 200);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerUsuarios()
    }, [])

    const createLabel = (item, type) => {
        if(type === "phone") return `${item.phone ? item.phone : "SIN NÚMERO"} · ${item.nombres}`
        return `${item.email} · ${item.nombres}`
    }

    let options_select = usuarios.map(u => ({ value: u[default_key_identifier], label: createLabel(u, typeLabel) })).filter(e => e.value)

    return <div>
        <label className="form-control-label">{label}</label>
        <Select 
            ref={selectRef}
            key={`cliente-${key}`}
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelect}
                        options={options_select}
                        isMulti={multi}
                        defaultValue={defaultValue}
                        isLoading={loading}
                        noOptionsMessage={()=>'Sin opciones'}
                        placeholder="Selecciona un usuario..."
                    />
    </div>
}

export default BuscadorUsuarios