import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import AutoComplete from "react-google-autocomplete";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { AiOutlineMail } from "react-icons/ai";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { codigos_area, codigos_pais } from "../../lib/internacional";
import { filterOption } from "../../lib/helpers/helpers";
import SelectorClientes from "../Clientes/selector";

const EditarContactoRapido = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = 'contactos'

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        if(!campo.nombres) return messageApi.error("Selecciona una dirección")

        setLoadingSave(true)
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const handleChangeCustom = (value,name) => {
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }
    
    const mostrarSelectorClientes = () => {
        return <div>
            <SelectorClientes key={key_input_filter} value={campo.id_cliente} titulo="Empresa asociada" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }

    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
                <Row gutter={15} >
                <Col md={24}>
                        {mostrarSelectorClientes()}
                        <Form.Item label="Estado" className="mb-2">
                        <Select options={[{ value: "", label: "No especificado" },{ value: 'active', label: "Activo" },{ value: "inactive", label: "Inactivo" }]} showSearch filterOption={filterOption} defaultValue={!campo.status ? "" : campo.status} name="status" onChange={(e) => handleChangeSelect("status", e)} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Nombres" required className="mb-2">
                        <Input name="nombres" defaultValue={campo.nombres} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Apellidos" className="mb-2">
                        <Input name="apellidos" defaultValue={campo.apellidos} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="mb-3">
                        <Form.Item label="Cargo" className="mb-2">
                        <Input name="cargo" defaultValue={campo.cargo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Área" className="mb-2">
                        <Input name="area" value={campo.area} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Dirección" className="mb-2">
                        <Input name="direccion" value={campo.direccion} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Ciudad" className="mb-2">
                        <Input name="ciudad" value={campo.ciudad} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="País" className="mb-2">
                        {/* <Input name="pais" value={campo.pais} onChange={handleChange} /> */}
                        <Select options={codigos_pais} showSearch filterOption={filterOption} defaultValue={campo.pais} name="pais" onChange={(e) => handleChangeSelect("pais", e)} />
                        </Form.Item>
                    </Col>
                    <Col md={24} className="mb-3">
                        <Form.Item label="Nota" className="mb-2">
                        <Input name="notas" defaultValue={campo.notas} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={24} className="mb-3">
                        <Title level={5} className="mt-0 mb-2"><AiOutlineMail /> Email</Title>
                        <Input name="email" placeholder="Escribe tu email" defaultValue={campo.email} onChange={handleChange} />
                    </Col>
                    
                    <Col md={24}>
                        <Title level={5} className="mt-0 mb-2"><GoDeviceMobile /> Móvil</Title>
                    </Col>
                    <Col md={12}>
                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" defaultValue={campo.pais_codigo} onChange={(e) => handleChangeCustom(e,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                    </Col>
                    <Col md={12} className="mb-3">
                        <Input name="movil" defaultValue={campo.movil} onChange={handleChange} />
                    </Col>
                    <Col md={24}>
                        <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
                    </Col>
                </Row>
                
            </Form>
        </div>
    }
    
    return <div>
        <Button  className="m-0" size="small" type="dashed" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Editar" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarContactoRapido